import { AuthContext } from "../../context/AuthContext";
import NotificationSnackbar from "../helpers/notification-snackbar";
import { addImageClassToContent } from "../helpers/utils";
import { AvatarStyledBadge } from "../helpers/utils";
import MemberProfileDrawer from "../members/MemberProfileDrawer";
// For a pencil/edit icon
import AcknowledgementTable from "./AcknowledgementTable";
import EditPost from "./EditPost";
import PostContent from "./PostContent";
import PostVideos from "./PostVideos";
import {
  MoreVert,
  DeleteForever,
  Edit,
  Visibility,
} from "@mui/icons-material/";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Chip,
  Box,
  Badge,
} from "@mui/material";
import React, { useState, useContext, memo } from "react";
import { Link } from "react-router-dom";

const AnnouncementPostCard = ({
  post,
  isPostList = false,
  isPostPage = false,
  isPostHomePageList = false,
  onDialogOpen,
  onMigratePostToNewCategoryDialogOpen,
  refetchPosts,
  currentUserId,
  onSelect,
  isNewPost,
  viewMode,
}) => {
  const { accountType, userRoleTiers } = useContext(AuthContext);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleMenuOpen = (event, postId) => {
    event.stopPropagation();
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: event.currentTarget });
  };

  const handleMenuClose = (postId) => {
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: null });
  };

  const handleExpandClick = (postId) => {
    setExpandedPosts({
      ...expandedPosts,
      [postId]: !expandedPosts[postId],
    });
  };

  const handleOpenEditPostDialog = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setOpenEditPostDialog(true);
  };

  const handleCloseEditPostDialog = () => {
    setOpenEditPostDialog(false);
    handleMenuClose(post?._id);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleMemberClick = (memberId) => {
    setSelectedMemberId(memberId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedMemberId(null);
  };

  const isAdmin = accountType === "reosadmin" || accountType === "brokerage";
  const isAuthor = post?.author?._id === currentUserId;
  const isAuthorOrAdmin = isAuthor || isAdmin;

  // const isBrokerageOrReosAdmin = isAuthorOrAdmin;

  const isBrokerageOrReosAdmin =
    isAuthor ||
    accountType === "reosadmin" ||
    (accountType === "brokerage" &&
      userRoleTiers.includes(post?.tier) &&
      post?.tier?.level !== "system");

  // Check Ack Date
  const currentUserIdString = currentUserId?.toString(); // Ensure currentUserId is defined before calling toString

  const acknowledgedEntry = post?.acknowledgedBy?.find(
    (ack) => ack.user?._id.toString() === currentUserIdString
  );
  const acknowledgedDate = acknowledgedEntry?.acknowledgedAt;

  // Check Ack List
  const acknowledgeList = post?.acknowledgedBy;

  const isAllOffices =
    post?.tier &&
    post?.tier?.brokerage &&
    post?.tier?.brokerage?.leadBrokerageTier &&
    post?.tier?._id === post?.tier?.brokerage?.leadBrokerageTier?._id;

  const viewedBy = post?.accessedBy
    .map((access) => access?.firstName + " " + access?.lastName)
    .join(", ");

  return (
    <Card
      sx={{ marginBottom: 2 }}
      className={`post-card ${
        viewMode === "grid-view" ? "grid-view" : "list-view"
      }`}
    >
      {isNewPost && <Box className="post-card-new-post-label">NEW POST</Box>}
      {isPostHomePageList &&
        post?.tier &&
        post?.tier?.brokerage?.officeName && (
          <Box className="post-card-tier-label">
            {`${post?.tier?.brokerage?.officeName}${
              isAllOffices ? " - all offices" : ""
            }` || "Unknown Office"}
          </Box>
        )}
      {(!isPostList || accountType === "reosadmin") && (
        <CardHeader
          className="post-card-header"
          avatar={
            <Tooltip title="Member Profile link">
              <AvatarStyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color="success"
                invisible={!post?.author?.isOnline}
                width="5px"
                height="5px"
              >
                <Avatar
                  className="avatar"
                  src={post?.author?.image?.url}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                >
                  R
                </Avatar>
              </AvatarStyledBadge>
            </Tooltip>
          }
          action={
            <>
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, post?._id)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id={`menu-${post?._id}`}
                anchorEl={menuAnchorEl[post?._id]}
                open={Boolean(menuAnchorEl[post?._id])}
                onClose={() => handleMenuClose(post?._id)}
              >
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenEditPostDialog(event);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Edit Post</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onMigratePostToNewCategoryDialogOpen(post);
                      handleMenuClose(post?._id);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Migrate Post To New Category</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onDialogOpen(post?._id);
                    }}
                  >
                    <DeleteForever />
                    <Typography ml={1}>Delete Post</Typography>
                  </MenuItem>
                )}
                {!isAuthorOrAdmin && (
                  <MenuItem>
                    <Typography ml={1}>No actions</Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          }
          title={
            <div className="card-header-title">
              <Tooltip title="Member Profile link">
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                  className="avatar-name"
                >
                  {post?.author?.firstName} {post?.author?.lastName}
                </Link>
              </Tooltip>
              <Typography className="category-text">
                {" posted in "}
                {post?.category?.name}
              </Typography>
            </div>
          }
          subheader={
            <Typography className="date-text">
              {new Date(post?.createdAt).toLocaleString()}
            </Typography>
          }
        />
      )}
      <CardHeader
        title={
          <div className="post-card-title-wrap">
            <Typography className="post-card-title">
              {post?.postTitle}
            </Typography>
            {post?.accessCount > 0 && (
              <Tooltip
                title={`Post Viewed ${post?.accessCount} time${
                  post?.accessCount === 1 ? "" : "s"
                }  ${isAdmin ? ` ${viewedBy}` : ""}`}
              >
                <Badge badgeContent={post?.accessCount} color="primary">
                  <Visibility />
                </Badge>
              </Tooltip>
            )}
          </div>
        }
        sx={{ cursor: "pointer" }}
        onClick={(event) => {
          if (isPostList && !isDrawerOpen && !isEditing) {
            onSelect(event);
          }
        }}
      />
      <CardContent className="custom-card-content">
        {viewMode !== "grid-view" && post?.videos && post.videos.length > 0 && (
          <PostVideos videos={post.videos} isPostPage={isPostPage} />
        )}
        <PostContent
          content={addImageClassToContent(
            post?.content || "No content available"
          )}
          isExpanded={expandedPosts[post?._id]}
          onExpandClick={(event) => {
            event.stopPropagation();
            handleExpandClick(post?._id);
          }}
          isPostPage={isPostPage}
          viewMode={viewMode}
        />
        <Box>
          {viewMode !== "grid-view" && post && post?.lastEdited && (
            <Typography
              variant="caption"
              color="textSecondary"
              textAlign="right"
            >
              <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
              Last edited on {new Date(post?.lastEdited)?.toLocaleString()}
            </Typography>
          )}
        </Box>
        <Box>
          {post && acknowledgedDate && (
            <Typography variant="caption" color="green" textAlign="right">
              <CheckCircleIcon
                sx={{ color: "green", verticalAlign: "middle" }}
              />
              Acknowledged on {new Date(acknowledgedDate)?.toLocaleString()}
            </Typography>
          )}
        </Box>
        {!isPostList && post && post?.tags && post?.tags?.length > 0 && (
          <Box
            sx={{ mt: 2, mb: 2, display: "flex", flexWrap: "wrap", gap: 0.5 }}
          >
            {post && post?.tags && post?.tags?.length > 0
              ? post?.tags.map((tag, index) => (
                  <Chip key={index} label={tag} variant="outlined" />
                ))
              : post?.tags
                  .split(",")
                  .map((tag, index) => (
                    <Chip key={index} label={tag.trim()} variant="outlined" />
                  ))}
          </Box>
        )}
        {viewMode !== "grid-view" && isBrokerageOrReosAdmin && (
          <AcknowledgementTable
            acknowledgeList={acknowledgeList}
            onMemberClick={handleMemberClick}
            postId={post?._id}
          />
        )}
      </CardContent>
      {/* <CardActions className="post-card-actions-block">
        <div className="post-card-actions">
          <div className="likes-block">
            <Tooltip title={isLikedByCurrentUser ? "Unlike it" : "Like it"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  isLikedByCurrentUser ? handleUnlikePost() : handleLikePost();
                }}
              >
                {isLikedByCurrentUser ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
              </IconButton>
            </Tooltip>
            <Tooltip title={"Comment the Post"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIsComment(!isComment);
                }}
              >
                <Comment />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </CardActions> */}
      <EditPost
        refetchPosts={refetchPosts}
        post={post}
        open={openEditPostDialog}
        onClose={() => {
          handleCloseEditPostDialog();
          handleMenuClose(post?._id);
        }}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MemberProfileDrawer
        userId={selectedMemberId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Card>
  );
};

export default memo(AnnouncementPostCard);
