import React, { memo } from "react";
import {
  Typography,
  Button,
  Divider,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const MigrationResultsModal = ({ open, onClose, migratedListings }) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="migrated-listings-title"
      aria-describedby="migrated-listings-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <Typography id="migrated-listings-title" variant="h6" component="h2">
          Migrated Listings
        </Typography>
        <Divider sx={{ my: 2 }} />
        {migratedListings?.length > 0 ? (
          <List>
            {migratedListings?.map((listing) => (
              <ListItem key={listing?.listingId}>
                <ListItemText primary={`agent ID: ${listing?.agent}`} />
                <ListItemText
                  primary={`Listing ID: ${listing?.listingId}`}
                  secondary={`Address: ${listing?.address}`}
                />
                <ListItemText
                  primary={`post ID: ${listing?.postId}`}
                  secondary={`category: ${listing?.category}`}
                />
              </ListItem>
            ))}
            {/* 
              postId: post._id,
              category:
                post.category && post.category.type ? post.category.type : "no cat",
              listingId: listing._id,
              address: listing.address,
              agent: listing.agent,

            */}
          </List>
        ) : (
          <Typography>No listings were migrated.</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default memo(MigrationResultsModal);
