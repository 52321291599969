import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGet5YearStatsTierTransactionsByIdQuery } from "../../../services/tier";
import {
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableFooter,
  TableRow,
  Paper,
  TextField,
  TableSortLabel,
} from "@mui/material";
import { memo } from "react";

const SalesManager = ({ tier }) => {
  const navigate = useNavigate();

  const {
    data: memberData,
    isLoading,
    isError,
  } = useGet5YearStatsTierTransactionsByIdQuery(tier?._id, {
    skip: !tier?._id,
  });

  const [filter, setFilter] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "user.firstName",
    direction: "asc",
  });

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filteredAndSortedData = useMemo(() => {
    if (!memberData) return [];

    let filteredData = [...memberData]; // Create a copy of the array

    if (filter) {
      filteredData = memberData.filter((member) =>
        `${member.user?.firstName} ${member.user?.lastName}`
          .toLowerCase()
          .includes(filter.toLowerCase())
      );
    }

    if (sortConfig.key) {
      filteredData = filteredData.sort((a, b) => {
        const aValue = sortConfig.key.split(".").reduce((o, i) => o[i], a);
        const bValue = sortConfig.key.split(".").reduce((o, i) => o[i], b);

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [memberData, filter, sortConfig]);

  const totals = useMemo(() => {
    if (!filteredAndSortedData.length) return {};

    return filteredAndSortedData.reduce(
      (acc, member) => {
        acc.active += member.stats.active;
        acc.pending += member.stats.pending;
        return acc;
      },
      {
        active: 0,
        pending: 0,
      }
    );
  }, [filteredAndSortedData]);

  if (isLoading) {
    return (
      <Container maxWidth="md">
        <CircularProgress />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error">
          Error loading transactions.
        </Typography>
      </Container>
    );
  }
  const navigateToProfile = (id) => {
    navigate(`/member-profile/${id}`);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Brokerage Transactions
      </Typography>
      <TextField
        label="Filter by Member Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filter}
        onChange={handleFilterChange}
      />
      {filteredAndSortedData?.length > 0 ? (
        <Paper elevation={3} sx={{ p: 2 }}>
          <TableContainer component={Paper} sx={{ maxHeight: 740 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "user.firstName"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("user.firstName")}
                    >
                      Member Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.active"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.active")}
                    >
                      Active Listings{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.pending"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.pending")}
                    >
                      Pending Transactions{" "}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.total"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.total")}
                    >
                      Rolling 12mnth Transactions
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.buyers"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.buyers")}
                    >
                      Buyer Side Transactions
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.sellers"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.sellers")}
                    >
                      Listing Side Transactions
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.avgTotal"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.avgTotal")}
                    >
                      5 Year Avg Total
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.avgBuyers"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.avgBuyers")}
                    >
                      5 Year Avg Buyer Side
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      active={sortConfig.key === "stats.avgSellers"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("stats.avgSellers")}
                    >
                      5 Year Avg Listing Side
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedData.map((member) => {
                  const ytdTotalColor =
                    member.stats.total < member.stats.avgTotal
                      ? "red"
                      : member.stats.total > member.stats.avgTotal
                      ? "#2E8B57"
                      : "transparent";

                  const ytdBuyerColor =
                    member.stats.buyers < member.stats.avgBuyers
                      ? "red"
                      : member.stats.buyers > member.stats.avgBuyers
                      ? "#2E8B57"
                      : "transparent";

                  const ytdListingColor =
                    member.stats.sellers < member.stats.avgSellers
                      ? "red"
                      : member.stats.sellers > member.stats.avgSellers
                      ? "#2E8B57"
                      : "transparent";

                  const textColor = (bgColor) =>
                    bgColor === "red" || bgColor === "#2E8B57"
                      ? "white"
                      : "black";

                  const fontWeight = (bgColor) =>
                    bgColor === "red" || bgColor === "#2E8B57"
                      ? "bold"
                      : "normal";

                  return (
                    <TableRow
                      key={member._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => navigateToProfile(member.user._id)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        {member.user?.firstName} {member.user?.lastName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {member.stats.active}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {member.stats.pending}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: ytdTotalColor,
                          color: textColor(ytdTotalColor),
                          fontWeight: fontWeight(ytdTotalColor),
                        }}
                      >
                        {member.stats.total}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: ytdBuyerColor,
                          color: textColor(ytdBuyerColor),
                          fontWeight: fontWeight(ytdBuyerColor),
                        }}
                      >
                        {member.stats.buyers}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: ytdListingColor,
                          color: textColor(ytdListingColor),
                          fontWeight: fontWeight(ytdListingColor),
                        }}
                      >
                        {member.stats.sellers}
                      </TableCell>
                      <TableCell align="right">
                        {member.stats.avgTotal}
                      </TableCell>
                      <TableCell align="right">
                        {member.stats.avgBuyers}
                      </TableCell>
                      <TableCell align="right">
                        {member.stats.avgSellers}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Totals
                  </TableCell>
                  <TableCell align="left">{totals.active}</TableCell>
                  <TableCell align="left">{totals.pending}</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography variant="body1">No transactions found.</Typography>
      )}
    </Container>
  );
};

export default memo(SalesManager);
