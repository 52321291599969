import React, { useState, useContext, useEffect, useMemo } from "react";
import { useInviteNewMemberMutation } from "../../../services/invitation";
import { useGetAllTiersQuery } from "../../../services/tier";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";
import { titleCase } from "title-case";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const InviteNewMemberDialog = ({ open, onClose }) => {
  const { userId, accountType } = useContext(AuthContext);
  const [memberEmail, setMemberEmail] = useState("");
  const [inviteNewMember, { isLoading }] = useInviteNewMemberMutation();
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const { data: userRoleNameEnums } = useGetAllUserRoleNameEnumsQuery(
    accountType,
    {
      skip: accountType !== "reosadmin",
    }
  );

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const [inputrolenameenumvalue, setInputrolenameenumvalue] = useState("");
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedRoleNameEnumId, setSelectedRoleNameEnumId] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await inviteNewMember({
      memberEmail,
      userId,
      tierId: selectedTierId?._id,
      userRoleNameEnumId: selectedRoleNameEnumId?._id,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error sending the member invitation link. ${result?.error?.data?.msg}`,
      });
    }
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(memberEmail));
  }, [memberEmail]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  // This function assumes 'level' is a string. Adjust the comparison if 'level' is numeric or requires custom logic.
  function sortTiersByLevel(a, b) {
    const levelA = a.level.toUpperCase(); // to ensure case-insensitive comparison
    const levelB = b.level.toUpperCase();

    if (levelA < levelB) {
      return -1;
    }
    if (levelA > levelB) {
      return 1;
    }
    return 0;
  }

  const sortedTiers = useMemo(() => {
    return [...(tiers || [])].sort(sortTiersByLevel);
  }, [tiers]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Invite New Member </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Autocomplete
                value={selectedRoleNameEnumId}
                onChange={(event, newValue) => {
                  setSelectedRoleNameEnumId(newValue);
                }}
                inputValue={inputrolenameenumvalue}
                onInputChange={(event, newInputValue) => {
                  setInputrolenameenumvalue(newInputValue);
                }}
                options={userRoleNameEnums || []}
                getOptionLabel={(option) =>
                  option ? option?.userRoleName : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a User Role"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                label="Member Email"
                name="Member Email"
                value={memberEmail}
                onChange={(e) => setMemberEmail(e.target.value.toLowerCase())}
                fullWidth
                required
                onCopy={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                value={selectedTierId}
                onChange={(event, newValue) => {
                  setSelectedTierId(newValue);
                }}
                inputValue={inputtiervalue}
                onInputChange={(event, newInputValue) => {
                  setInputtiervalue(newInputValue);
                }}
                options={sortedTiers}
                getOptionLabel={(option) =>
                  option
                    ? `${titleCase(option?.level)} ${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.officeName ||
                            option?.team?.name ||
                            option?.group?.name
                          : ""
                      } ${
                        option?.level === "system" || option?.level === "global"
                          ? " - REACirc"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.leadBrokerageTier
                          ? " - (Lead Brokerage Office)"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.tier
                          ? " - (Office)"
                          : ""
                      }`
                    : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Tier"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!isEmailValid || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Invite Member"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default InviteNewMemberDialog;
