import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  DialogContent,
  TextField,
  MenuItem,
  Select,
  FormControl,
  FormLabel,
  InputLabel,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { AuthContext } from "../../../../context/AuthContext";
import {
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from "../../../../services/billing/subscriptions";
import { useFetchSubscriptionPlansQuery } from "../../../../services/billing/subscriptionPlans";
import { useFetchAllTiersQuery } from "../../../../services/tier";
import { useFetchAllUsersQuery } from "../../../../services/user";

const SubscriptionForm = forwardRef(({ subscription = null, onClose }, ref) => {
  const { userId } = useContext(AuthContext);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [account, setAccount] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTier, setSelectedTier] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [status, setStatus] = useState("active");
  const [, setLoadingTiers] = useState(true);

  const { data: users } = useFetchAllUsersQuery();
  const { data: tiers, isLoading: tiersLoading } = useFetchAllTiersQuery();
  const { data: plans } = useFetchSubscriptionPlansQuery();

  const [createSubscription, { isLoading: isCreating }] =
    useCreateSubscriptionMutation();
  const [updateSubscription, { isLoading: isUpdating }] =
    useUpdateSubscriptionMutation();

  useEffect(() => {
    if (tiers) {
      setLoadingTiers(tiersLoading);
    }
  }, [tiers, tiersLoading]);

  useEffect(() => {
    if (subscription) {
      
      if (subscription.plan.clientType === "individual") {
        
        setAccount(subscription.account || "");
      } else {
        setSelectedTier(subscription.tier || "");
      }
      setDescription(subscription.description || "");
      setSubscriptionPlan(subscription.plan || "");
      setStartDate(
        subscription.startDate
          ? new Date(subscription.startDate).toISOString().split("T")[0]
          : ""
      );
      setEndDate(
        subscription.endDate
          ? new Date(subscription.endDate).toISOString().split("T")[0]
          : ""
      );
      setBillingCycle(subscription.billingCycle || "monthly");
      setStatus(subscription.status || "active");
    }
  }, [subscription, plans]);

  const handlePlanChange = (event) => {
    const selectedPlanId = event.target.value;

    const lookedUpPlan = plans.find((plan) => plan._id === selectedPlanId);

    if (lookedUpPlan) {
      setSubscriptionPlan(lookedUpPlan);
      if (lookedUpPlan.clientType === "individual") {
        setSelectedTier(""); // Clear tier for individual
      } else {
        setAccount(""); // Clear account for brokerage
      }
    }
  };

  const handleSubmit = () => {
    if (!subscriptionPlan) {
      setNotification({
        open: true,
        message: "Subscription Plan is required.",
        type: "warning",
      });
      return;
    }
    if (subscriptionPlan?.clientType === "individual" && !account) {
      setNotification({
        open: true,
        message: "Account is required.",
        type: "warning",
      });
      return;
    }

    if (subscriptionPlan?.clientType === "brokerage" && !selectedTier) {
      setNotification({
        open: true,
        message: "Tier is required.",
        type: "warning",
      });
      return;
    }

    if (!startDate) {
      setNotification({
        open: true,
        message: "Start Date is required.",
        type: "warning",
      });
      return;
    }

    const subscriptionData = {
      account,
      description,
      tier: selectedTier,
      plan: subscriptionPlan,
      startDate,
      endDate,
      billingCycle,
      status,
      createdBy: userId,
      updatedBy: userId,
    };

    if (subscriptionPlan && billingCycle) {
      const action = subscription ? updateSubscription : createSubscription;
      const actionData = subscription
        ? { _id: subscription._id, ...subscriptionData }
        : subscriptionData;

      action(actionData)
        .then(() => {
          setNotification({
            open: true,
            message: `Subscription ${
              subscription ? "updated" : "created"
            } successfully!`,
            type: "success",
          });
          onClose();
        })
        .catch((error) => {
          setNotification({
            open: true,
            message: `Failed to ${
              subscription ? "update" : "create"
            } subscription plan: ${error.message}`,
            type: "error",
          });
        });
    } else {
      setNotification({
        open: true,
        message: "Please provide valid subscription plan details.",
        type: "warning",
      });
    }
  };

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  return (
    <>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Subscription Plan</InputLabel>
          <Select
            value={subscriptionPlan?._id || ""}
            onChange={handlePlanChange}
            disabled={isCreating || isUpdating}
            label="Subscription Plan"
            variant="outlined"
          >
            {plans &&
              plans.map((subplan) => (
                <MenuItem key={subplan._id} value={subplan._id}>
                  {subplan.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {subscriptionPlan?.clientType === "individual" && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Account</InputLabel>
            <Select
              value={account || ""}
              onChange={(e) => setAccount(e.target.value)}
              disabled={isCreating || isUpdating}
              label="Account"
            >
              {users &&
                users.map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user.firstName} {user.lastName} - {user.email}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {subscriptionPlan?.clientType === "brokerage" && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Tier</InputLabel>
            <Select
              value={selectedTier._id || ""}
              onChange={(e) => setSelectedTier(e.target.value)}
              disabled={isCreating || isUpdating}
              label="Tier"
            >
              {tiers && tiers.length > 0 ? (
                tiers
                  .filter(
                    (tier) =>
                      tier.type === "brokerage" && tier.brokerage?.officeName
                  )
                  .map((tier) => (
                    <MenuItem key={tier._id} value={tier._id}>
                      {tier.brokerage?.officeName || "Unknown"}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem value="">No tiers available</MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth margin="normal">
          <FormLabel>Description</FormLabel>
          <TextField
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={isCreating || isUpdating}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel component="legend">Start Date</FormLabel>
          <TextField
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={isCreating || isUpdating}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel component="legend">End Date</FormLabel>
          <TextField
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            disabled={isCreating || isUpdating}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Billing Cycle</InputLabel>
          <Select
            value={billingCycle}
            onChange={(e) => setBillingCycle(e.target.value)}
            disabled={isCreating || isUpdating}
            label="Billing Cycle"
          >
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            disabled={isCreating || isUpdating}
            label="Status"
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification({ ...notification, open: false })}
      />
    </>
  );
});

export default SubscriptionForm;
