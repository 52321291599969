import { AuthContext } from "../../context/AuthContext";
import { useLazyDownloadPhotographQuery } from "../../services/post";
import { useCommentOnPostMutation } from "../../services/post";
import NotificationSnackbar from "../helpers/notification-snackbar";
import { formatMoney } from "../helpers/utils";
import { truncate } from "../helpers/utils";
import { countCommentsAndReplies, AvatarStyledBadge } from "../helpers/utils";
import MemberProfileDrawer from "../members/MemberProfileDrawer";
import CommentCard from "./CommentCard";
import EditPost from "./EditPost";
import PostVideos from "./PostVideos";
import {
  MoreVert,
  DeleteForever,
  ExpandMore,
  Edit,
  Send,
  Comment,
  Visibility,
} from "@mui/icons-material/";
import EditIcon from "@mui/icons-material/Edit";
// For a pencil/edit icon
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Divider,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  TextField,
  Chip,
  Box,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  Button,
  Badge,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect, useContext, memo } from "react";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";

const ExpandComments = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
}));

const ListingPostCard = ({
  post,
  isPostList = false,
  isPostPage = false,
  isPostHomePageList = false,
  onDialogOpen,
  onMigratePostToNewCategoryDialogOpen,
  refetchPosts,
  currentUserId,
  onSelect,
  isPostsLoading,
  isPostsError,
  isNewPost,
  viewMode,
}) => {
  const { accountType, userImage, userName } = useContext(AuthContext);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isComment, setIsComment] = useState("");
  const [commentOnPost] = useCommentOnPostMutation();
  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [expandedComments, setExpandedComments] = useState(false);
  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [photographUrls, setPhotgraphUrls] = useState({});
  const [trigger, { data: downloadedPhotograph }] =
    useLazyDownloadPhotographQuery();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (post?.photographs) {
      post.photographs.forEach((photograph) => {
        if (!photograph.url.includes("cloudinary")) {
          trigger({ photographId: photograph._id }).then((result) => {
            if (result?.data?.url) {
              setPhotgraphUrls((prev) => ({
                ...prev,
                [photograph._id]: result.data?.url,
              }));
            }
          });
        } else {
          setPhotgraphUrls((prev) => ({
            ...prev,
            [photograph._id]: photograph.url,
          }));
        }
      });
    }
  }, [post?.photographs, trigger]);

  const totalCommentsCount =
    post?.comments && countCommentsAndReplies(post?.comments);

  const handleMenuOpen = (event, postId) => {
    event.stopPropagation();
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: event.currentTarget });
  };

  const handleMenuClose = (postId) => {
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: null });
  };

  const handleExpandCommentsClick = () => {
    setExpandedComments(!expandedComments);
  };

  const handleOpenEditPostDialog = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setOpenEditPostDialog(true);
  };

  const handleCloseEditPostDialog = () => {
    setOpenEditPostDialog(false);
    handleMenuClose(post?._id);
  };

  const handleCommentInputClick = (event) => {
    event.stopPropagation();
  };

  const handleCommentSubmit = async (event) => {
    event && event?.stopPropagation();
    const result = await commentOnPost({
      userId: currentUserId,
      postId: post?._id,
      content: newComment,
      tierId: post?.tier?._id,
    });

    if (result) {
      setNotification({
        open: true,
        message: `Post commented successfully`,
      });
      setNewComment("");
    } else {
      setNotification({
        open: true,
        message: `Error submitting comment ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCommentSubmit();
    }
  };

  const handleMemberClick = (memberId) => {
    setSelectedMemberId(memberId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedMemberId(null);
  };

  const renderPhotographs = (photographs) => {
    if (!photographs) return;

    return photographs.map((photo, i) => (
      <Paper key={i} elevation={0} className="aspect-ratio-16-9">
        <img
          src={photographUrls[photo?._id]}
          alt={`Photograph ${photo?._id}`}
          className="aspect-ratio-content"
        />
      </Paper>
    ));
  };

  // Table settings
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const isAuthor = post?.author?._id === currentUserId;
  const isAuthorOrAdmin =
    isAuthor || accountType === "reosadmin" || accountType === "brokerage";
  const isAdmin = accountType === "reosadmin" || accountType === "brokerage";
  const isAllOffices =
    post?.tier &&
    post?.tier?.brokerage &&
    post?.tier?.brokerage?.leadBrokerageTier &&
    post?.tier?._id === post?.tier?.brokerage?.leadBrokerageTier?._id;

  const viewedBy = post?.accessedBy
    .map((access) => access?.firstName + " " + access?.lastName)
    .join(", ");

  return (
    <Card
      sx={{ marginBottom: 2 }}
      className={`post-card ${
        viewMode === "grid-view" ? "grid-view" : "list-view"
      }`}
    >
      {isNewPost && <Box className="post-card-new-post-label">NEW POST</Box>}
      {isPostHomePageList &&
        post?.tier &&
        post?.tier?.brokerage?.officeName && (
          <Box className="post-card-tier-label">
            {`${post?.tier?.brokerage?.officeName}${
              isAllOffices ? " - all offices" : ""
            }` || "Unknown Office"}
          </Box>
        )}
      {!isPostList && (
        <CardHeader
          className="post-card-header"
          avatar={
            <Tooltip title="Member Profile link">
              <AvatarStyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color="success"
                invisible={!post?.author?.isOnline}
                width="5px"
                height="5px"
              >
                <Avatar
                  className="avatar"
                  src={post?.author?.image?.url}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                >
                  R
                </Avatar>
              </AvatarStyledBadge>
            </Tooltip>
          }
          action={
            <>
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, post?._id)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id={`menu-${post?._id}`}
                anchorEl={menuAnchorEl[post?._id]}
                open={Boolean(menuAnchorEl[post?._id])}
                onClose={() => handleMenuClose(post?._id)}
              >
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenEditPostDialog(event);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Edit Post</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onMigratePostToNewCategoryDialogOpen(post);
                      handleMenuClose(post?._id);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Migrate Post To New Category</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onDialogOpen(post?._id);
                    }}
                  >
                    <DeleteForever />
                    <Typography ml={1}>Delete Post</Typography>
                  </MenuItem>
                )}
                {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                  <MenuItem>
                    <Typography ml={1}>No actions</Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          }
          title={
            <div className="card-header-title">
              <Tooltip title="Member Profile link">
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                  className="avatar-name"
                >
                  {post?.author?.firstName} {post?.author?.lastName}
                </Link>
              </Tooltip>
              <Typography className="category-text">
                {" posted in "}
                {post?.category?.name}
              </Typography>
            </div>
          }
          subheader={
            <Typography className="date-text">
              {new Date(post?.createdAt).toLocaleString()}
            </Typography>
          }
        />
      )}
      <CardHeader
        title={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography className="post-card-address">
                {post?.listingDetails?.address?.suit
                  ? post?.listingDetails?.address?.suit + " - "
                  : ""}{" "}
                {post?.listingDetails?.address?.street} (
                {post?.listingDetails?.address?.city})
              </Typography>
              <Typography className="post-card-list-price">
                {post?.listingDetails?.listPrice
                  ? "$" + formatMoney(post?.listingDetails.listPrice, 0)
                  : "TBD"}
              </Typography>
            </div>
            <Typography className="post-card-listing-title">
              {post?.postTitle}
            </Typography>
            {post?.accessCount > 0 && (
              <Tooltip
                title={`Post Viewed ${post?.accessCount} time${
                  post?.accessCount === 1 ? "" : "s"
                }  ${isAdmin ? ` ${viewedBy}` : ""}`}
              >
                <Badge badgeContent={post?.accessCount} color="primary">
                  <Visibility />
                </Badge>
              </Tooltip>
            )}
          </>
        }
        sx={{ cursor: "pointer" }}
        onClick={(event) => {
          if (isPostList && !isDrawerOpen && !isEditing) {
            onSelect(event);
          }
        }}
      />
      <div
        style={{
          position: "relative", // Ensure this div is positioned
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0 0 1rem 0",
        }}
      >
        {post?.listingDetails?.listingDate &&
          new Date(post.listingDetails.listingDate) > new Date() && (
            <div className="coming-soon-banner">
              COMING SOON -{" "}
              {new Date(post?.listingDetails.listingDate).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              )}
            </div>
          )}
        {viewMode !== "grid-view" && (
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible={true}
            animation="slide"
            swipe={true}
            duration={500}
          >
            {post?.photographs && post.photographs.length > 0 ? (
              renderPhotographs(post.photographs)
            ) : (
              <Paper
                key="comingsoon"
                elevation={3}
                className="aspect-ratio-16-9"
              >
                <img
                  src="/imgs/Home-img-coming-soon.jpg"
                  alt="coming soon"
                  className="aspect-ratio-content"
                />
              </Paper>
            )}
          </Carousel>
        )}
      </div>
      <CardContent className="listing-custom-card-content">
        {viewMode === "list-view" && post?.videos && post.videos.length > 0 && (
          <PostVideos videos={post.videos} isPostPage={isPostPage} />
        )}
        <TableContainer>
          <Table size="small">
            <TableBody>
              {viewMode === "list-view" && (
                <StyledTableRow
                  key={post?._id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableCell width={110} className="listing-table-label">
                    Area
                  </StyledTableCell>
                  <StyledTableCell>{post?.listingDetails.area}</StyledTableCell>
                  <StyledTableCell width={120} className="listing-table-label">
                    Bedrooms
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails.bedrooms}
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell className="listing-table-label">
                    SubArea
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails.subarea}
                  </StyledTableCell>
                  <StyledTableCell className="listing-table-label">
                    Bathrooms
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails.bathrooms}
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell className="listing-table-label">
                    Total Sqft
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails?.totalSqft?.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell className="listing-table-label">
                    Finished Sqft
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails?.finishedSqft?.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell className="listing-table-label">
                    Lot Sqft
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails?.lotSqft?.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell className="listing-table-label">
                    Lot Acres
                  </StyledTableCell>
                  <StyledTableCell>
                    {post?.listingDetails?.lotAcres?.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              )}
              <StyledTableRow>
                <StyledTableCell colSpan={1} className="listing-table-label">
                  Entered By
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  <Tooltip title="Member Profile link">
                    <Link
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMemberClick(post?.author?._id);
                      }}
                    >
                      {post?.author?.firstName} {post?.author?.lastName}
                    </Link>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={1} className="listing-table-label">
                  Listing Date
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  <Typography className="date-text">
                    {new Date(
                      post?.listingDetails.listingDate
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bolder" }}
                    >
                      Listing Highlights
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <Typography variant="body1" gutterBottom>
                      <span
                        dangerouslySetInnerHTML={{ __html: post?.description }}
                      />
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bolder" }}
                    >
                      Listing Writeup
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {viewMode !== "grid-view" && (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <Typography variant="body1" gutterBottom>
                      {isPostList
                        ? truncate(post?.listingDetails?.writeup, 200) + "..."
                        : post?.listingDetails?.writeup}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {viewMode !== "grid-view" &&
                post?.listingDetails?.videoLinks &&
                post.listingDetails.videoLinks.length > 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      {post?.listingDetails?.videoLinks?.map(
                        (videoLink, index) => (
                          <Grid item key={index}>
                            <Chip
                              label={`Video ${index + 1}`}
                              component="a"
                              href={videoLink}
                              clickable
                            />
                          </Grid>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                )}
              {!isPostList && post?.tags && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box
                      className="tag-box"
                      sx={{
                        mt: 2,
                        mb: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {Array.isArray(post.tags)
                        ? post.tags.map((tag, index) => (
                            <Chip key={index} label={tag} variant="outlined" />
                          ))
                        : post.tags
                            .split(",")
                            .map((tag, index) => (
                              <Chip
                                key={index}
                                label={tag.trim()}
                                variant="outlined"
                              />
                            ))}
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={4}>
                  {post?.lastEdited && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      textAlign="right"
                    >
                      <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
                      Last edited on{" "}
                      {new Date(post?.lastEdited)?.toLocaleString()}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              {isPostList && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div>
                      <Button
                        size="medium"
                        onClick={(event) => {
                          if (isPostList && !isDrawerOpen && !isEditing) {
                            onSelect(event);
                          }
                        }}
                        variant="outlined"
                        color="success"
                      >
                        Listing Info...
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Divider />
      <CardActions className="post-card-actions-block">
        <div className="post-card-actions">
          <div className="likes-block">
            <Tooltip title={"Comment the Post"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIsComment(!isComment);
                }}
              >
                <Comment />
              </IconButton>
            </Tooltip>
          </div>
          <div className="comments-block">
            {post?.comments && post?.comments?.length > 0 ? (
              <>
                <span className="comments-block-span">{`${
                  post?.comments &&
                  post?.comments?.length > 0 &&
                  (totalCommentsCount === 1
                    ? "1 comment"
                    : `${totalCommentsCount} comments`)
                }`}</span>
                <ExpandComments
                  expand={expandedComments.toString()}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleExpandCommentsClick();
                  }}
                  aria-expanded={expandedComments}
                  aria-label="show more"
                >
                  <ExpandMore />
                </ExpandComments>
              </>
            ) : (
              <span className="comments-block-span">no comments</span>
            )}
          </div>
        </div>
      </CardActions>
      <Collapse in={expandedComments} timeout="auto" unmountOnExit>
        <CardContent>
          {post?.comments && post?.comments?.length > 0 ? (
            post?.comments?.map((comment) => (
              <CommentCard
                key={comment?._id}
                comment={comment}
                post={post}
                onMemberClick={handleMemberClick}
                refetchPosts={refetchPosts}
                isPostsError={isPostsError}
                isPostsLoading={isPostsLoading}
                isListingPostCard={true}
              />
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No comments yet
            </Typography>
          )}
          {expandedComments && (
            <div
              className="post-comment-block"
              onClick={handleCommentInputClick}
            >
              <Avatar
                height="100"
                width="100"
                src={userImage || ""}
                alt={userName || "UN"}
              />
              <div className="comment-input">
                <TextField
                  className="comment-input-textarea"
                  placeholder="New Comment"
                  value={newComment}
                  onChange={(e) => {
                    e.stopPropagation();
                    setNewComment(e.target.value);
                  }}
                  fullWidth
                  onKeyDown={handleKeyDown}
                  multiline
                  rows={3}
                  onClick={handleCommentInputClick}
                />
                <Tooltip title="Comment Post">
                  <IconButton
                    className="comment-input-button"
                    onClick={handleCommentSubmit}
                  >
                    <Send />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
        </CardContent>
      </Collapse>
      {isComment && !expandedComments && (
        <div className="post-comment-block" onClick={handleCommentInputClick}>
          <Avatar
            height="100"
            width="100"
            src={userImage || ""}
            alt={userName || "UN"}
          />
          <div className="comment-input">
            <TextField
              className="comment-input-textarea"
              placeholder="New Comment"
              value={newComment}
              onChange={(e) => {
                e.stopPropagation();
                setNewComment(e.target.value);
              }}
              fullWidth
              onKeyDown={handleKeyDown}
              multiline
              rows={3}
              onClick={handleCommentInputClick}
            />
            <Tooltip title="Comment Post">
              <IconButton
                className="comment-input-button"
                onClick={handleCommentSubmit}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <EditPost
        refetchPosts={refetchPosts}
        post={post}
        open={openEditPostDialog}
        onClose={() => {
          handleCloseEditPostDialog();
          handleMenuClose(post?._id);
        }}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MemberProfileDrawer
        userId={selectedMemberId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Card>
  );
};

export default memo(ListingPostCard);
