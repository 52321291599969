import DOMPurify from "dompurify";

export function replaceGoogleSheetsLinks(content) {
  const regexGoogleSheets =
    /(?:<a[^>]*href=["'])?https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)(\/edit?usp=sharing)?(?:[^"'>]*>.*?<\/a>)?/g;

  const sanitizedContent = DOMPurify.sanitize(content);
  let lastIndex = 0;
  let match;
  const parts = [];
  let hasGoogleSheetsLink = false;

  while ((match = regexGoogleSheets.exec(sanitizedContent)) !== null) {
    const start = match.index;
    const end = regexGoogleSheets.lastIndex;

    const sheetId = match[1];

    if (start > lastIndex) {
      parts.push(sanitizedContent.substring(lastIndex, start));
    }
    if (sheetId) {
      parts.push(
        <iframe
          title="Google Sheets"
          key={sheetId}
          src={`https://docs.google.com/spreadsheets/d/${sheetId}/edit?usp=sharing`}
          width="100%"
          height="900"
          frameBorder="0"
          allow="fullscreen; autoplay; encrypted-media"
        />
      );
      hasGoogleSheetsLink = true;
    }
    lastIndex = end;
  }

  if (lastIndex < sanitizedContent.length) {
    parts.push(sanitizedContent.substring(lastIndex));
  }

  return { parts, hasGoogleSheetsLink };
}
