import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  CardMedia,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  CircularProgress,
  Tooltip,
  Paper,
  IconButton,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import {
  useUpdateImageMutation,
  useUpdateUserNameMutation,
} from "../../services/user";
import { useGetDutyScheduleOfficeLocationsByUserRoleQuery } from "../../services/duty-schedule";
import {
  useGetMemberProfileQuery,
  useUpdateMemberProfileMutation,
} from "../../services/member";
import NotificationSnackbar from "../helpers/notification-snackbar";
import { timezones, isValidHttpUrl, daysOfWeek } from "../helpers/utils";
import TimeSlotSelector from "./TimeSlotSelector";
import UnavailablePeriodSelector from "./UnavailablePeriodSelector";
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
dayjs.extend(utc);

const EditMemberProfile = ({
  userMemberId,
  memberProfileEditFrom,
  onClose,
}) => {
  const { userId, updateUserImage, accountType } = useContext(AuthContext);
  const {
    data: member,
    isLoading,
    refetch,
  } = useGetMemberProfileQuery(userMemberId, {
    skip: !userMemberId,
  });

  const { data: dutyScheduleOfficeLocations } =
    useGetDutyScheduleOfficeLocationsByUserRoleQuery(userMemberId, {
      skip: !userMemberId,
    });

  const [updateMemberProfile] = useUpdateMemberProfileMutation();
  const [memberData, setMemberData] = useState({
    profileTitle: "",
    email: "",
    isEmailPrivate: false,
    emailPublic: "",
    website: "",
    profession: "",
    bio: "",
    tags: [],
    timezone: "",
    certifications: [],
    awards: [],
    yearLicensed: "",
    licenseNumber: "",
    hobbies: [],
    geographicAreasServiced: [],
    gender: "",
    dealsPerYear: "",
    birthday: { day: "", month: "" },
    phone: "",
    socialMediaLinks: [],
    conferenceParticipantType: "",
    otherLinks: [],
    currentLocation: {
      city: "",
      state: "",
      country: "",
    },
    preferredCommunicationMethod: "",
    willingToDoDuty: false,
    availability: { days: [], timeSlots: [] },
    unavailability: [],
    officeLocations: [],
    unavailablePeriods: [],
  });
  const [unavailability, setUnavailability] = useState([]);
  const [unavailableDays, setUnavailableDays] = useState([]);
  const [updateImage] = useUpdateImageMutation();
  const [selectedDays, setSelectedDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [updateUserName] = useUpdateUserNameMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await updateImage({
          userId: userMemberId,
          file,
        });
        if (response.error) {
          throw response.error;
        }
        if (response?.data) {
          setNotification({
            open: true,
            message: "Image updated successfully!",
          });
          refetch?.();
          setTimeout(() => {
            if (accountType !== "reosadmin" && userId === member?.user) {
              updateUserImage(response?.data?.image?.url);
            }
          }, 100);
        }
      } catch (error) {
        setNotification({
          open: true,
          message: `Failed to update image: ${error?.data?.msg}`,
        });
      }
    }
  };

  const handleSaveUserName = async () => {
    const result = await updateUserName({
      userId: member?.user,
      firstName,
      lastName,
      isEmailPrivate: memberData?.isEmailPrivate,
      emailPublic: memberData?.emailPublic,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: "Name saved successfully.",
      });
      setTimeout(() => {
        refetch();
        if (memberProfileEditFrom) {
          onClose();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };
  const handleSubmit = async () => {
    if (memberData?.website && !isValidHttpUrl(memberData.website)) {
      setNotification({
        open: true,
        message: "Please enter a valid URL for the website.",
      });
      return;
    }

    const updatedMemberData = {
      ...memberData,
      availability: {
        days: selectedDays,
        timeSlots: timeSlots,
      },
      unavailablePeriods: [...memberData.unavailablePeriods],
      officeLocations: memberData?.officeLocations?.map((office) => ({
        officeName: office?.officeName,
        location: office?.location,
      })),
      unavailability: [...unavailability],
    };

    const resultUser = await updateUserName({
      userId: member?.user,
      firstName,
      lastName,
      isEmailPrivate: memberData?.isEmailPrivate,
      emailPublic: memberData?.emailPublic,
    });

    if (!resultUser.data) {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          resultUser.error.data?.msg || "Unknown error"
        }`,
      });
    }

    const result = await updateMemberProfile({
      userId: userMemberId,
      updateData: updatedMemberData,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: "Changes saved successfully.",
      });
      setTimeout(() => {
        refetch();
        if (memberProfileEditFrom) {
          onClose();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleOfficeChange = (officeName, location) => {
    setMemberData((prevState) => {
      const officeExists = prevState.officeLocations?.some(
        (office) => office.officeName === officeName
      );

      if (!officeExists) {
        return {
          ...prevState,
          officeLocations: [
            ...prevState.officeLocations,
            { officeName, location },
          ],
        };
      } else {
        return {
          ...prevState,
          officeLocations: prevState.officeLocations?.map((office) =>
            office.officeName === officeName ? { officeName, location } : office
          ),
        };
      }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "yearLicensed") {
      const yearValue = value.slice(0, 4);
      setMemberData({ ...memberData, [name]: yearValue });
    } else if (name === "isEmailPrivate") {
      // Convert the string value "yes" or "no" to a boolean
      const booleanValue = value === "yes";

      setMemberData({ ...memberData, [name]: booleanValue });
    } else if (name === "day" || name === "month") {
      setMemberData({
        ...memberData,
        birthday: { ...memberData.birthday, [name]: value || "" },
      });
    } else {
      setMemberData({ ...memberData, [name]: value || "" });
    }
  };

  const handleArrayChange = (name, value) => {
    const arrayValues = value.split(/, +/).map((item) => item);
    setMemberData((prevData) => ({
      ...prevData,
      [name]: arrayValues,
    }));
  };

  const handleDaysChange = (event) => {
    const { value } = event.target;
    setSelectedDays(typeof value === "string" ? value.split(",") : value);
  };

  const handleAddTimeSlot = (slot) => {
    setTimeSlots([...timeSlots, slot]);
  };

  const handleRemoveTimeSlot = (index) => {
    setTimeSlots((prevSlots) => prevSlots.filter((_, i) => i !== index));
  };

  const handleAddUnavailablePeriod = (period) => {
    setMemberData((prevData) => ({
      ...prevData,
      unavailablePeriods: [...prevData.unavailablePeriods, period],
    }));
  };

  const handleRemoveUnavailablePeriod = (index) => {
    setMemberData((prevData) => ({
      ...prevData,
      unavailablePeriods: prevData.unavailablePeriods.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleAddUnavailability = (days, timeSlot) => {
    setUnavailability((prevUnavailability) => {
      const updatedUnavailability = prevUnavailability.map((u) => ({
        ...u,
        timeSlots: [...u.timeSlots],
      }));

      days.forEach((day) => {
        const existingDayIndex = updatedUnavailability.findIndex(
          (u) => u.day === day
        );
        if (existingDayIndex !== -1) {
          const dayData = updatedUnavailability[existingDayIndex];
          if (
            !dayData.timeSlots.some(
              (slot) =>
                slot.startTime === timeSlot.startTime &&
                slot.endTime === timeSlot.endTime
            )
          ) {
            updatedUnavailability[existingDayIndex] = {
              ...dayData,
              timeSlots: [...dayData.timeSlots, timeSlot],
            };
          }
        } else {
          updatedUnavailability.push({ day, timeSlots: [timeSlot] });
        }
      });
      return updatedUnavailability;
    });
  };

  const handleRemoveUnavailability = (index) => {
    setUnavailability((prevUnavailability) =>
      prevUnavailability.filter((_, i) => i !== index)
    );
  };

  const handleDaysChangeForUnavailability = (event) => {
    const { value } = event.target;
    const newSelectedDays =
      typeof value === "string" ? value.split(",") : value;

    setUnavailableDays(newSelectedDays);

    setUnavailability((prevUnavailability) => {
      const filteredUnavailability = prevUnavailability.filter((u) =>
        newSelectedDays.includes(u.day)
      );

      const newEntries = newSelectedDays
        .filter((day) => !filteredUnavailability.some((u) => u.day === day))
        .map((day) => ({ day, timeSlots: [] }));

      return [...filteredUnavailability, ...newEntries];
    });
  };

  useEffect(() => {
    if (member) {
      setFirstName(member.firstName || "");
      setLastName(member.lastName || "");
      setMemberData((prevState) => ({
        ...prevState,
        profileTitle: member.profileTitle || "",
        email: member.email || "",
        emailPublic: member.emailPublic || "",
        isEmailPrivate: member.isEmailPrivate || false,
        website: member.website || "",
        bio: member.bio || "",
        phone: member.phone || "",
        timezone: member.timezone || "Default_Timezone",
        certifications: member.certifications || [],
        awards: member.awards || [],
        yearLicensed: member.yearLicensed || "",
        licenseNumber: member.licenseNumber || "",
        conferenceParticipantType:
          member.conferenceParticipantType || "Attendee",
        hobbies: member.hobbies || [],
        geographicAreasServiced: member.geographicAreasServiced || [],
        gender: member.gender || "Default_Gender",
        dealsPerYear: member.dealsPerYear || "",
        birthday: member.birthday
          ? member.birthday
          : { name: "", city: "", stateProvince: "", country: "" },
        office: member.office ? member.office : { day: "", month: "" },
        socialMediaLinks: member.socialMediaLinks || [],
        otherLinks: member.otherLinks || [],
        currentLocation: member.currentLocation || {
          city: "",
          state: "",
          country: "",
        },
        preferredCommunicationMethod:
          member.preferredCommunicationMethod || "Default_Method",
        willingToDoDuty: member.willingToDoDuty || true,
        availability: member.availability || { days: [], timeSlots: [] },
        unavailablePeriods: member.unavailablePeriods || [],
      }));
      setSelectedDays(member.availability?.days || []);
      setTimeSlots(member.availability?.timeSlots || []);

      setUnavailability(member.unavailability || []);
      setUnavailableDays((member.unavailability || []).map((u) => u.day));
    }
  }, [member]);

  useEffect(() => {
    setMemberData((prevData) => ({
      ...prevData,
      officeLocations: member?.officeLocations || [],
      availability: {
        ...prevData.availability,
        days: selectedDays,
        timeSlots: timeSlots,
      },
    }));
  }, [selectedDays, timeSlots]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  return (
    <div
      className={`${
        memberProfileEditFrom
          ? "member-profile-edit-form-from-mp"
          : "member-profile-edit-form-from-mp"
      }`}
    >
      <Grid container className="member-profile-edit-form-body">
        <Grid item container className="member-profile-edit-form-header">
          <div className="header-profile-image-block">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-image-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="upload-image-file">
              <Tooltip title="Upload Photo" arrow>
                <IconButton color="primary" component="span">
                  <CardMedia
                    component="img"
                    className="member-profile-image"
                    image={member?.image?.url}
                    alt={`${member?.firstName} ${member?.lastName}`}
                  />
                </IconButton>
              </Tooltip>
            </label>
            <Button
              variant="text"
              component="span"
              onClick={() =>
                document.getElementById("upload-image-file").click()
              }
            >
              {member?.image?.url ? "Change Photo" : "Add Photo"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditMemberProfile;
