import React, {
  forwardRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useImperativeHandle,
} from "react";
import { AuthContext } from "../../../../context/AuthContext";
import {
  DialogContent,
  TextField,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
} from "@mui/material";
import dayjs from "dayjs";
import {
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useGetUserCountByTierIdQuery,
} from "../../../../services/billing/invoices";
import { useFetchSubscriptionsQuery } from "../../../../services/billing/subscriptions";

const InvoiceForm = forwardRef(
  ({ onClose, refetchInvoices, invoice = null, editable = true }, ref) => {
    const { userId } = useContext(AuthContext);
    const [createInvoice] = useCreateInvoiceMutation();
    const [updateInvoice] = useUpdateInvoiceMutation();

    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [amount, setAmount] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [currency, setCurrency] = useState("CAD");
    const [notes, setNotes] = useState("");
    const [selectedTier, setSelectedTier] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
    const [subscription, setSubscription] = useState(null);
    const [oneTimeCharges, setOneTimeCharges] = useState([]);
    const [taxDetails, setTaxDetails] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [statusHistory, setStatusHistory] = useState([]);
    const [status, setStatus] = useState("unpaid");
    const [viewType] = useState("individual");
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);

    const { data: subscriptions } = useFetchSubscriptionsQuery();
    const { data: tierUserCount } = useGetUserCountByTierIdQuery(selectedTier, {
      skip: selectedTier === "",
    });

    useEffect(() => {
      if (subscriptions) {
        setLoadingSubscriptions(false);
      }
    }, [subscriptions]);

    useEffect(() => {
      if (invoice) {
        setInvoiceNumber(invoice.invoiceNumber || "");

        setInvoiceDate(
          dayjs(invoice.dateOfInvoice).utc().toISOString().split("T")[0]
        );

        setDueDate(dayjs(invoice.dueDate).utc().toISOString().split("T")[0]);
        setAmount(invoice.totalAmount || "");
        setCurrency(invoice.currency || "CAD");
        setNotes(invoice.notes || "");
        setStatus(invoice.status || "");
        setSelectedTier(invoice.tiers?.[0] || "");
        setSelectedClient(invoice.users?.[0] || "");
        setSelectedProducts(invoice.products || []);
        setSelectedSubscriptionId(invoice.subscription._id || "");
        setSubscription(invoice.subscription || "");
        setOneTimeCharges(invoice.oneTimeCharges || []);
        setTaxDetails(invoice.taxDetails || []);
        setPaymentDetails(invoice.paymentDetails || []);
        setStatusHistory(invoice.statusHistory || []);
      } else {
        const today = new Date();
        const dueDate = new Date().setDate(today.getDate() + 30);

        setInvoiceDate(dayjs(today).utc().toISOString().split("T")[0]);
        setDueDate(dayjs(dueDate).utc().toISOString().split("T")[0]);
      }
    }, [invoice]);

    useEffect(() => {
      if (selectedSubscriptionId) {
        const selectedSubscription = subscriptions?.find(
          (sub) => sub._id === selectedSubscriptionId
        );
        setSubscription(selectedSubscription || null);
        if (selectedSubscription) {
          setSelectedProducts(
            selectedSubscription.plan?.productCollections.map(
              (product) => product._id
            ) || []
          );
          setSelectedTier(selectedSubscription.tier?._id || "");
        }
      } else {
        setSubscription(null);
        setSelectedProducts([]);
      }
    }, [selectedSubscriptionId, subscriptions]);

    const calculateSubtotal = useCallback(() => {
      const userCount = tierUserCount?.userCount || 1;
      if (subscription) {
        return subscription.plan.productCollections.reduce(
          (total, collection) => {
            return (
              total +
              collection.products.reduce((collectionTotal, product) => {
                const quantity = product.perUser ? userCount : 1;
                return collectionTotal + quantity * product.price;
              }, 0)
            );
          },
          0
        );
      } else {
        return 0;
      }
    }, [subscription, tierUserCount]);

    const calculateDiscount = useCallback(() => {
      if (subscription) {
        return subscription.plan.productCollections.reduce(
          (totalDiscount, collection) => {
            const collectionSubtotal = collection.products.reduce(
              (collectionTotal, product) => {
                const quantity = product.perUser
                  ? tierUserCount?.userCount || 1
                  : 1;
                return collectionTotal + quantity * product.price;
              },
              0
            );

            const discountAmount =
              (collectionSubtotal * (collection.discount || 0)) / 100;

            return totalDiscount + discountAmount;
          },
          0
        );
      } else {
        return 0;
      }
    }, [subscription, tierUserCount]);

    const calculateTax = useCallback(
      (subtotal) => {
        return taxDetails.reduce((total, tax) => total + tax.amount, 0);
      },
      [taxDetails]
    );

    const calculateTotal = useCallback(() => {
      const subtotal = calculateSubtotal();
      const discount = calculateDiscount();
      const tax = calculateTax(subtotal - discount);
      return subtotal - discount + tax;
    }, [calculateSubtotal, calculateDiscount, calculateTax]);

    useEffect(() => {
      // Update the amount with the total once it's calculated
      setAmount(calculateTotal().toFixed(2));
    }, [calculateTotal]);

    const handleSubmit = async () => {
      if (amount && dueDate) {
        const invoiceData = {
          invoiceNumber: invoiceNumber || "System Generated",
          dateOfInvoice: invoiceDate,
          totalAmount: parseFloat(amount),
          dueDate,
          currency,
          notes,
          status,
          tiers: [selectedTier],
          users: viewType === "individual" ? [selectedClient] : [],
          products: selectedProducts || [],
          subscriptions: selectedSubscriptionId ? [selectedSubscriptionId] : [],
          subscription,
          oneTimeCharges,
          taxDetails,
          paymentDetails,
          statusHistory,
          createdBy: userId,
        };

        try {
          const action = invoice ? updateInvoice : createInvoice;
          const actionData = invoice
            ? { _id: invoice._id, ...invoiceData }
            : invoiceData;
          await action(actionData).unwrap();
          onClose();
        } catch (error) {
          console.error("Failed to save invoice:", error);
          // Show a user-friendly message here
        }
      } else {
        console.error("Please provide valid invoice details.");
        // Show a validation error message here
      }
    };
    const getDiscount = () => {
      if (subscription) {
        // Assuming discount is applied to the entire subscription
        return subscription.plan.productCollections.reduce(
          (totalDiscount, collection) => {
            return totalDiscount + (collection.discount || 0);
          },
          0
        );
      }
      return 0;
    };
    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit();
      },
    }));

    return (
      <DialogContent>
        <Box sx={{ width: "80%", margin: "0 auto" }}>
          <InputLabel>Subscriptions</InputLabel>
          {loadingSubscriptions ? (
            <Typography>Loading...</Typography>
          ) : (
            <Select
              value={selectedSubscriptionId}
              onChange={(e) => setSelectedSubscriptionId(e.target.value)}
              fullWidth
              margin="dense"
              disabled={!editable}
            >
              {subscriptions?.map((subscription) => (
                <MenuItem key={subscription._id} value={subscription._id}>
                  {subscription.tier?.brokerage?.officeName
                    ? "Business: " + subscription.tier.brokerage.officeName
                    : "Agent: " +
                      (subscription.account?.firstName || "Unknown") +
                      " " +
                      (subscription.account?.lastName || "Unknown")}
                </MenuItem>
              ))}
            </Select>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <TextField
              label="Invoice Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={invoiceDate}
              onChange={(e) => setInvoiceDate(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ mr: 0 }}
              InputProps={{ readOnly: !editable }}
            />
            <TextField
              label="Due Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ ml: 1 }}
              InputProps={{ readOnly: !editable }}
            />
            <FormControl
              fullWidth
              margin="normal"
              sx={{ ml: 1 }}
              disabled={!editable}
            >
              <InputLabel shrink>Invoice Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                fullWidth
                variant="outlined"
                label="Invoice Status"
              >
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="unpaid">Unpaid</MenuItem>
                <MenuItem value="collections">Collections</MenuItem>
                <MenuItem value="partial">Partial</MenuItem>
                <MenuItem value="void">Void</MenuItem>
                <MenuItem value="overpaid">Overpaid</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <InputLabel>Invoice Details</InputLabel>
          <Box mb={2}>
            <Box sx={{ mt: 1, px: 1 }}>
              {subscription?.plan?.productCollections?.length > 0 ? (
                <Box>
                  {subscription.plan.productCollections.map((pc) => (
                    <Box sx={{ mb: 2 }} key={pc._id}>
                      <Typography variant="h6" component="div">
                        Product Collection: <strong>{pc.name}</strong>
                      </Typography>
                      {pc.products.length > 0 ? (
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Product Code</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pc.products.map((product) => {
                                const quantity = product.perUser
                                  ? tierUserCount?.userCount
                                  : 1; // Default to 1 if userCount is not available
                                const total = quantity * product.price;

                                return (
                                  <TableRow key={product._id}>
                                    <TableCell>{product.productCode}</TableCell>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>
                                      ${product.price.toFixed(2)}
                                    </TableCell>
                                    <TableCell>{product.currency}</TableCell>
                                    <TableCell>{quantity}</TableCell>
                                    <TableCell>${total.toFixed(2)}</TableCell>
                                  </TableRow>
                                );
                              })}

                              <TableRow>
                                <TableCell colSpan={5} align="right">
                                  Subtotal
                                </TableCell>
                                <TableCell align="right">
                                  ${calculateSubtotal().toFixed(2)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5} align="right">
                                  {getDiscount()}% Discount
                                </TableCell>
                                <TableCell align="right">
                                  ${calculateDiscount().toFixed(2)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5} align="right">
                                  Tax
                                </TableCell>
                                <TableCell align="right">
                                  $
                                  {calculateTax(
                                    calculateSubtotal() - calculateDiscount()
                                  ).toFixed(2)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5} align="right">
                                  <strong>Total</strong>
                                </TableCell>
                                <TableCell align="right">
                                  <strong>
                                    ${calculateTotal().toFixed(2)}
                                  </strong>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography>
                          No products available in this collection.
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography>
                  No product collections available for this subscription.
                </Typography>
              )}
            </Box>
          </Box>

          <TextField
            label="Notes"
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: !editable }}
          />
        </Box>
      </DialogContent>
    );
  }
);

export default InvoiceForm;
