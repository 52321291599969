import React, { useRef, useEffect, useState, useMemo, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Paper,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import {
  useFetchInvoicesQuery,
  useDeleteInvoiceMutation,
} from "../../../../services/billing/invoices";
import { useCreateTransactionMutation } from "../../../../services/billing/transactions";
import InvoiceForm from "./InvoiceForm";
import InvoiceTable from "./InvoiceTable";
import ConfirmationDialog from "../ConfirmationDialog";
import PaymentForm from "../payment/PaymentForm";
import CreditCardPaymentDialog from "../payment/CreditCardPaymentDialog";
import { AuthContext } from "../../../../context/AuthContext";

const Invoices = (accountId, brokerageId) => {
  const { userId } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(
    parseInt(localStorage.getItem("inc"), 10) || 0
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isCreditCardDialogOpen, setIsCreditCardDialogOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTask, setConfirmationTask] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [paymentDataForCreditCard, setPaymentDataForCreditCard] =
    useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("invoiceNumber");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [paymentButtonLabel, setPaymentButtonLabel] =
    useState("Register Payment");

  const {
    data: invoices = [],
    error,
    refetch: refetchInvoices,
  } = useFetchInvoicesQuery();
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [createTransaction] = useCreateTransactionMutation();

  const invoiceFormRef = useRef(null);
  const paymentFormRef = useRef(null);

  useEffect(() => {
    if (error) {
      setSnackbarMessage(`Error fetching invoices: ${error.message}`);
      setSnackbarOpen(true);
    }
  }, [error]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    localStorage.setItem("inc", newValue);
  };

  const handleDeleteClick = (item, type) => {
    setItemToDelete({ ...item, type });
    setConfirmationMessage(`Are you sure you want to delete this ${type}?`);
    setConfirmationTitle("Delete Item");
    setConfirmationTask("Delete");
    setIsConfirmOpen(true);
  };
  const handlePaymentClick = (item, type) => {
    setSelectedInvoice({ ...item, type });
    setIsPaymentDialogOpen(true);
  };

  const handleConfirm = async () => {
    try {
      await deleteInvoice(itemToDelete._id).unwrap();
      refetchInvoices();
      setSnackbarMessage("Invoice deleted successfully.");
      setSnackbarOpen(true);
      setIsConfirmOpen(false);
      setItemToDelete(null);
    } catch (error) {
      setSnackbarMessage(`Error deleting Invoice: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleConfirmationCancel = () => {
    setIsConfirmOpen(false);
    setItemToDelete(null);
  };

  const handleSavePayment = async (paymentData, paymentDetails = null) => {
    try {
      // Set payment data for credit card if required
      setPaymentDataForCreditCard(paymentData);
      // If the payment method is credit card and payment details are not provided,
      // open the credit card dialog and prevent further processing
      if (paymentData.paymentMethod === "credit_card" && !paymentDetails) {
        setIsCreditCardDialogOpen(true);
        return; // Prevent processing payment immediately
      }

      // Update paymentData with confirmation number if paymentDetails is available
      if (paymentDetails) {
        paymentData = {
          ...paymentData,
          confirmationNumber: paymentDetails.clientSecret, // Add confirmation number from paymentDetails
          createdBy: userId,
        };
      }

      // Create a transaction with the updated paymentData
      await createTransaction(paymentData).unwrap();

      // Refetch invoices to update the UI with the latest data
      await refetchInvoices();

      // Show success message and close dialogs
      setSnackbarMessage("Payment processed successfully.");
      setSnackbarOpen(true);
      setIsPaymentDialogOpen(false);
      // setisCreditCardDialogOpen(false);
      setSelectedInvoice(null);
    } catch (error) {
      // Handle errors and display error message
      setSnackbarMessage(`Error processing payment: ${error.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleCreditCardPaymentSuccess = (paymentData, paymentDetails) => {
    setIsPaymentDialogOpen(false);
    setIsCreditCardDialogOpen(false);
    handleSavePayment(paymentData, paymentDetails);
  };

  const handlePaymentClose = () => {
    setIsPaymentDialogOpen(false);
    setSelectedInvoice(null);
  };

  const handleInvoiceSave = async () => {
    if (invoiceFormRef.current) {
      await invoiceFormRef.current.submit();
      setSnackbarMessage(
        currentInvoice
          ? "Invoice updated successfully."
          : "Invoice created successfully."
      );
      setSnackbarOpen(true);
      await refetchInvoices();
    }
  };

  const handlePaymentSave = async () => {
    if (paymentFormRef.current) {
      await paymentFormRef.current.submit();
      refetchInvoices();
      setSnackbarMessage("Payment updated successfully.");
      setSnackbarOpen(true);
    }
  };

  const handleInvoiceClose = () => {
    setCurrentInvoice(null);
    setIsInvoiceFormOpen(false);
    refetchInvoices();
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEditClick = (invoice) => {
    setCurrentInvoice(invoice);
    setIsInvoiceFormOpen(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredInvoices = useMemo(() => {
    return invoices
      .filter((invoice) =>
        [
          invoice.invoiceNumber,
          invoice.dateOfInvoice,
          invoice.totalAmount,
          invoice.dueDate,
          invoice.status,
          invoice.currency,
          invoice.notes,
        ].some(
          (field) =>
            field &&
            field.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
      .filter((invoice) =>
        activeTab === 0 ? invoice.status !== "paid" : invoice.status === "paid"
      );
  }, [invoices, searchQuery, activeTab]);

  const handlePaymentMethodChange = (paymentMethod) => {
    if (paymentMethod === "credit_card") {
      setPaymentButtonLabel("Make CreditCard Payment");
    } else {
      setPaymentButtonLabel("Register Payment");
    }
  };

  const handleSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const sortedInvoices = useMemo(() => {
    if (!filteredInvoices) return []; // Prevent the sort if filteredInvoices is undefined

    return filteredInvoices.sort((a, b) => {
      if (orderBy === "amount") {
        // Ensure amounts are compared as numbers
        const amountA = parseFloat(a.totalAmount);
        const amountB = parseFloat(b.totalAmount);

        return order === "asc" ? amountA - amountB : amountB - amountA;
      }

      if (orderBy === "invoiceNumber") {
        // Sort by invoice number
        return (
          (a.invoiceNumber < b.invoiceNumber ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }

      return 0;
    });
  }, [filteredInvoices, orderBy, order]);

  return (
    <Paper className="invoice-list">
      <Button
        sx={{ m: 2 }}
        variant="contained"
        color="primary"
        onClick={() => setIsInvoiceFormOpen(true)}
        startIcon={<Add />}
      >
        New Invoice
      </Button>

      {/* <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ m: 2 }}
      /> */}

      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Current Invoices" />
        <Tab label="Paid Invoices" />
      </Tabs>

      <InvoiceTable
        invoices={sortedInvoices}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        handleEditClick={handleEditClick}
        handleDeleteClick={(item) => handleDeleteClick(item, "invoice")}
        handlePaymentClick={handlePaymentClick}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog // Payment Dialog
        open={isPaymentDialogOpen}
        onClose={() => setIsPaymentDialogOpen(false)}
      >
        <DialogTitle>Payment Form</DialogTitle>
        <DialogContent>
          <PaymentForm
            invoice={selectedInvoice}
            ref={paymentFormRef}
            onClose={handlePaymentClose}
            onSubmit={handleSavePayment}
            onPaymentMethodChange={handlePaymentMethodChange}
            handleSnackbar={handleSnackbar}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePaymentClose}>Cancel</Button>
          <Button onClick={handlePaymentSave} color="primary">
            {paymentButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog // Inovice Form Dialog
        open={isInvoiceFormOpen}
        onClose={() => setIsInvoiceFormOpen(false)}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: "60%",
            maxWidth: "none", // This prevents the dialog from being limited to a small max width
          },
        }}
      >
        <DialogTitle>
          {currentInvoice ? "Edit Invoice" : "New Invoice"}
        </DialogTitle>
        <DialogContent>
          <InvoiceForm
            ref={invoiceFormRef}
            invoice={currentInvoice}
            refetch={refetchInvoices}
            onClose={handleInvoiceClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInvoiceClose}>Cancel</Button>
          <Button onClick={handleInvoiceSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <CreditCardPaymentDialog
        open={isCreditCardDialogOpen}
        brokerageId={selectedInvoice?.subscription?.tier?.brokerage?._id}
        paymentData={paymentDataForCreditCard}
        onClose={() => setIsCreditCardDialogOpen(false)}
        onSubmit={handleSavePayment}
        onSuccess={handleCreditCardPaymentSuccess}
      />

      <ConfirmationDialog
        open={isConfirmOpen}
        onConfirm={handleConfirm}
        onClose={handleConfirmationCancel}
        title={confirmationTitle}
        message={confirmationMessage}
        task={confirmationTask}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.includes("Error") ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Invoices;
