import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/AuthContext";
import { ActiveChatProvider } from "./context/ActiveChatContext";
import { SidebarProvider } from "./context/SidebarContext";
import { BrokerageEcoSystemProvider } from "./context/BrokerageEcoSystemContext";
import { MemberProfilePageProvider } from "./context/MemberProfilePageContext";
import { SocketProvider } from "./context/SocketContext";
import { CustomThemeProvider } from "./context/ThemeContext";
import { TierProvider } from "./context/TierContext";
import { LicenseInfo } from "@mui/x-license";

const reactAppMuiXLicenceKey = process.env.REACT_APP_MUI_X_LICENCE_KEY || "";
LicenseInfo.setLicenseKey(reactAppMuiXLicenceKey);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <TierProvider>
          <CustomThemeProvider>
            <BrokerageEcoSystemProvider>
              <MemberProfilePageProvider>
                <SidebarProvider>
                  <ActiveChatProvider>
                    <SocketProvider>
                      <App />
                    </SocketProvider>
                  </ActiveChatProvider>
                </SidebarProvider>
              </MemberProfilePageProvider>
            </BrokerageEcoSystemProvider>
          </CustomThemeProvider>
        </TierProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
