import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetBrokerageProfileByIdQuery } from "../../../services/brokerage";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { BackButton } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import BrokerageCard from "../../brokerage/brokerage-ecosytem/BrokerageCard";

const BrokerageCardPage = () => {
  const { brokerageId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: brokerage,
    refetch,
    error,
    isLoading,
  } = useGetBrokerageProfileByIdQuery(brokerageId, {
    skip: !brokerageId,
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!brokerage) {
    return (
      <Typography variant="h4" gutterBottom>
        No Brokerage Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;
  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
      </Box>
      <BrokerageCard refetchBrokerage={refetch} brokerage={brokerage} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default BrokerageCardPage;
