import { AuthContext } from "../../context/AuthContext";
import { useGetEventTypesQuery } from "../../services/event-type";
import { useEditPostMutation } from "../../services/post";
import RecordView from "../helpers/RecordView";
import { generateTags } from "../helpers/generateTags";
import NotificationSnackbar from "../helpers/notification-snackbar";
import { sanitizeContent } from "../helpers/sanitizeContent";
import {
  convertBlobToFile,
  getCroppedImageBlob,
  modules,
  generateUniqueFileName,
  compressImagesInContent,
  calculateContentLength,
} from "../helpers/utils";
import { Info, Close } from "@mui/icons-material/";
import {
  Box,
  Grid,
  Button,
  Switch,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  Chip,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  memo,
  useId,
} from "react";
import Cropper from "react-easy-crop";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

dayjs.extend(utc);

const MAX_CONTENT_LENGTH = 1000000;

function EditPost({ open, onClose, post, refetchPosts }) {
  const SQFT_PER_ACRE = 43560;
  const { userId, userRole } = useContext(AuthContext);
  const { data: eventTypes } = useGetEventTypesQuery(
    { userId },
    {
      skip: !userId,
    }
  );
  const [recordVideo, setRecordVideo] = useState(false);
  const [uploadVideo, setUploadVideo] = useState(false);

  const [editedPostTitle, setEditedPostTitle] = useState(post?.postTitle || "");
  const [editorContent, setEditorContent] = useState(post?.content || "");
  const [newCreateDate, setNewCreateDate] = useState(post?.createdAt || "");
  const [description, setDescription] = useState(post?.description || "");
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState(
    post?.attachments || []
  );
  const [deletedAttachmentIds, setDeletedAttachmentIds] = useState([]);
  const [photographs, setPhotographs] = useState([]);
  const [existingPhotographs, setExistingPhotographs] = useState(
    post?.photographs || []
  );
  const [deletedPhotographIds, setDeletedPhotographIds] = useState([]);
  const [editPost, { isLoading }] = useEditPostMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [currentImage, setCurrentImage] = useState(post?.image?.url);
  const [newImageFile, setNewImageFile] = useState(null);
  const [deletedImage, setDeletedImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [tags, setTags] = useState(post?.tags || []);
  const [currentTag, setCurrentTag] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [eventEditOption, setEventEditOption] = useState("");
  const [eventType, setEventType] = useState(post?.eventType?._id || "");
  const [showDateTime, setShowDateTime] = useState(false);

  const [listingData, setListingData] = useState({
    address: {
      suit: post?.listingDetails?.address?.suit || "",
      street: post?.listingDetails?.address?.street || "",
      city: post?.listingDetails?.address?.city || "",
      state: post?.listingDetails?.address?.state || "",
      country: post?.listingDetails?.address?.country || "",
    },
    listPrice: post?.listingDetails?.listPrice || "",
    listingDate: post?.listingDetails?.listingDate || "",
    bedrooms: post?.listingDetails?.bedrooms || "",
    bathrooms: post?.listingDetails?.bathrooms || "",
    totalSqft: post?.listingDetails?.totalSqft || "",
    finishedSqft: post?.listingDetails?.finishedSqft || "",
    lotSqft: post?.listingDetails?.lotSqft || "",
    lotAcres: post?.listingDetails?.lotAcres || "",
    area: post?.listingDetails?.area || "",
    subarea: post?.listingDetails?.subarea || "",
    writeup: post?.listingDetails?.writeup || "",
    comments: post?.listingDetails?.comments || "",
    links: post?.listingDetails?.links || [],
  });
  const [eventData, setEventData] = useState({
    address: {
      suit: post?.eventDetails?.address?.suit || "",
      street: post?.eventDetails?.address?.street || "",
      city: post?.eventDetails?.address?.city || "",
      state: post?.eventDetails?.address?.state || "",
      country: post?.eventDetails?.address?.country || "",
    },
    isOnline: post?.eventDetails?.isOnline || false,
    link: post?.eventDetails?.link || "",
    eventDate: post?.eventDetails?.eventDate
      ? dayjs(post.eventDetails.eventDate).local().toISOString()
      : dayjs().toISOString(),
    eventEndDate: post?.eventDetails?.eventEndDate
      ? dayjs(post.eventDetails.eventEndDate).local().toISOString()
      : dayjs().toISOString(),
    recurrence: post?.eventDetails?.recurrence || {
      frequency: "",
      interval: 1,
      endDate: null,
    },
  });
  const [sendNotifications, setSendNotifications] = useState(true);
  const [sendEmails, setSendEmails] = useState(true);
  const [sendTextMessages, setSendTextMessages] = useState(false);
  const [override, setOverride] = useState(false);
  const [existingVideos, setExistingVideos] = useState(post?.videos || []);
  const [deletedVideoIds, setDeletedVideoIds] = useState([]);
  const [contentLength, setContentLength] = useState(0);

  useEffect(() => {
    if (post) {
      setEditedPostTitle(post?.postTitle || "");
      setEventType(post?.eventType?._id || "");
      setEditorContent(post?.content || "");
      setNewCreateDate(post?.createdAt || "");
      setContentLength(calculateContentLength(post?.content) || "");
      setDescription(post?.description || "");
      setExistingAttachments(post?.attachments || []);
      setExistingPhotographs(post?.photographs || []);
      setCurrentImage(post?.image?.url || "");
      setTags(post?.tags || []);
      setListingData({
        address: {
          suit: post?.listingDetails?.address?.suit || "",
          street: post?.listingDetails?.address?.street || "",
          city: post?.listingDetails?.address?.city || "",
          state: post?.listingDetails?.address?.state || "",
          country: post?.listingDetails?.address?.country || "",
        },
        listPrice: post?.listingDetails?.listPrice || "",
        listingDate: post?.listingDetails?.listingDate || "",
        bedrooms: post?.listingDetails?.bedrooms || "",
        bathrooms: post?.listingDetails?.bathrooms || "",
        totalSqft: post?.listingDetails?.totalSqft || "",
        finishedSqft: post?.listingDetails?.finishedSqft || "",
        lotSqft: post?.listingDetails?.lotSqft || "",
        lotAcres: post?.listingDetails?.lotAcres || "",
        area: post?.listingDetails?.area || "",
        subarea: post?.listingDetails?.subarea || "",
        writeup: post?.listingDetails?.writeup || "",
        comments: post?.listingDetails?.comments || "",
        links: post?.listingDetails?.links || [],
      });
      setEventData({
        address: {
          suit: post?.eventDetails?.address?.suit || "",
          street: post?.eventDetails?.address?.street || "",
          city: post?.eventDetails?.address?.city || "",
          state: post?.eventDetails?.address?.state || "",
          country: post?.eventDetails?.address?.country || "",
        },
        isOnline: post?.eventDetails?.isOnline || false,
        link: post?.eventDetails?.link || "",
        eventDate:
          dayjs(post?.eventDetails?.eventDate).local().toISOString() || "",
        eventEndDate: post?.eventDetails?.eventEndDate
          ? dayjs(post?.eventDetails?.eventEndDate).local().toISOString()
          : null,
        recurrence: post?.eventDetails?.recurrence || {
          frequency: "",
          interval: 1,
          endDate: null,
        },
      });
      setExistingVideos(post?.videos || []);
      setDeletedVideoIds([]);
    }
  }, [post]);

  useEffect(() => {
    return () => {
      setRecordVideo(false);
    };
  }, []);

  const imageEditInputId = useId();
  const attachmentsEditInputId = useId();
  const photographsEditInputId = useId();
  const imageRangeCroppingEditInputId = useId();
  const [videoFiles, setVideoFiles] = useState([]);
  const [error, setError] = useState({
    startDateError: {
      hasError: false,
      message: "",
    },
    endDateError: {
      hasError: false,
      message: "",
    },
  });

  const handleEventTypeChange = (event) => {
    const newEventType = event.target.value;
    setEventType(newEventType);
  };
  const tomorrow = dayjs().startOf("tomorrow"); // Tomorrow for comparison

  const handleToggle = (event) => {
    setShowDateTime(event.target.checked);
  };

  const handleListingDateChange = (newValue) => {
    if (newValue && newValue.isBefore(tomorrow)) {
      // If the selected date is today or before, show an error or handle it
      setNotification({
        open: true,
        message: "Error: The date must be in the future.",
      });
      setError({
        ...error,
        startDateError: {
          hasError: true,
          message: "The start date must be in the future.",
        },
      });

      return; // Do not update the state if the date is invalid
    }
    setNotification({
      open: false,
      message: "",
    });
    setError({
      ...error,
      startDateError: {
        hasError: false,
        message: "",
      },
    });
    setListingData({
      ...listingData,
      listingDate: dayjs(newValue || new Date()),
    });
  };

  const handleEventDataChange = (event) => {
    const { name, value } = event.target;
    const nameParts = name.split(".");
    if (nameParts.length === 1) {
      setEventData({ ...eventData, [name]: value });
    } else {
      const [mainField, subField] = nameParts;
      setEventData({
        ...eventData,
        [mainField]: { ...eventData[mainField], [subField]: value },
      });
    }
  };

  const handleOnlineChange = (event) => {
    setEventData({
      ...eventData,
      isOnline: event.target.checked,
    });
  };

  const handleListingChange = (event) => {
    const { name, value } = event.target;
    const nameParts = name.split(".");

    let processedValue = "";
    if (name === "listPrice") {
      processedValue = value !== "" ? Number(value) : "";
    } else {
      processedValue = value;
    }
    // Prepare the updated data object
    const updatedData = { ...listingData };

    if (nameParts.length === 1) {
      updatedData[name] = processedValue;

      // Check if the changed field is lotSqft or lotAcres and calculate accordingly
      if (name === "lotSqft" && processedValue !== "") {
        const acres = processedValue / SQFT_PER_ACRE;
        updatedData["lotAcres"] = parseFloat(acres.toFixed(2)); // Round to two decimals
      } else if (name === "lotAcres" && processedValue !== "") {
        const sqft = processedValue * SQFT_PER_ACRE;
        updatedData["lotSqft"] = parseFloat(sqft.toFixed(2)); // Round to two decimals
      }
    } else {
      const [mainField, subField] = nameParts;
      updatedData[mainField] = {
        ...listingData[mainField],
        [subField]: processedValue,
      };

      // Handle nested fields for lot sizes if applicable
      if (subField === "lotSqft" && processedValue !== "") {
        const acres = processedValue / SQFT_PER_ACRE;
        updatedData[mainField]["lotAcres"] = parseFloat(acres.toFixed(2)); // Round to two decimals
      } else if (subField === "lotAcres" && processedValue !== "") {
        const sqft = processedValue * SQFT_PER_ACRE;
        updatedData[mainField]["lotSqft"] = parseFloat(sqft.toFixed(2)); // Round to two decimals
      }
    }

    setListingData(updatedData);
  };

  const handleEventEditOptionChange = (event) => {
    setEventEditOption(event.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setContentLength(calculateContentLength(content));
  };

  const handleDescriptionChange = (content) => {
    setDescription(content);
    setContentLength(calculateContentLength(content));
  };

  const handleImageChange = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      if (newImageFile) {
        const croppedBlob = await getCroppedImageBlob(
          croppedAreaPixels,
          currentImage
        );
        setCroppedImageBlob(croppedBlob);
      }
    },
    [currentImage, newImageFile]
  );

  const handleEditSubmit = async () => {
    if (contentLength > MAX_CONTENT_LENGTH) {
      setNotification({
        open: true,
        message: `Content length exceeds the maximum limit of ${MAX_CONTENT_LENGTH} characters. 
        Or may have too many (or large) embedded Images within the content. Please adjust your content.`,
      });
      return;
    }

    if (
      post?.category?.type !== "announcements" &&
      post?.category?.type !== "knowledge-base" &&
      post?.category?.type !== "event" &&
      post?.category?.type !== "educational-training" &&
      !editedPostTitle?.trim()
    ) {
      setNotification({
        open: true,
        message: "Please fill the Title field.",
      });
      return;
    }

    if (
      post?.category?.type !== "event" &&
      post?.category?.type !== "listing" &&
      !editorContent?.trim()
    ) {
      setNotification({
        open: true,
        message: "Please fill the content field.",
      });
      return;
    }

    if (
      post?.category?.type === "event" &&
      post?.recurrenceGroupId &&
      !eventEditOption
    ) {
      setNotification({
        open: true,
        message:
          "Please select whether to edit the current event post or all recurring event posts.",
      });
      return;
    }

    if (post?.category?.type === "event" && !eventType?.trim()) {
      setNotification({
        open: true,
        message: "Please choose the event type.",
      });
      return;
    }
    if (!post?.tier?._id?.trim()) {
      setNotification({
        open: true,
        message: "The Tier ID is missing.",
      });
      return;
    }

    const compressedContent = await compressImagesInContent(editorContent);
    const sanitizedContent = sanitizeContent(compressedContent).__html;

    const formData = new FormData();
    formData.append("postTitle", editedPostTitle);
    formData.append("createDate", newCreateDate);
    formData.append("content", sanitizedContent);
    formData.append("sendNotifications", sendNotifications);
    formData.append("sendEmails", sendEmails);
    formData.append("sendTextMessages", sendTextMessages);
    formData.append("override", override);

    if (newImageFile) {
      const croppedImageFile = convertBlobToFile(
        croppedImageBlob,
        generateUniqueFileName()
      );
      formData.append("image", croppedImageFile);
    }

    if (deletedImage) {
      formData.append("deletedImage", true);
    }

    if (tags && tags?.length > 0) {
      tags.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag);
      });
    }

    if (post?.category?.type === "listing") {
      const serializedListingData = JSON.stringify(listingData);
      formData.append("listingDetails", serializedListingData);
      if (description) {
        formData.append("description", description);
      }
    }

    if (post?.category?.type === "event") {
      formData.append("editAllRecurringPosts", eventEditOption);

      eventData.eventDate = dayjs(eventData.eventDate).utc().toISOString();
      if (eventData.eventEndDate) {
        eventData.eventEndDate = dayjs(eventData.eventEndDate)
          .utc()
          .toISOString();
      }
      const serializedEventData = JSON.stringify(eventData);
      formData.append("eventDetails", serializedEventData);
      formData.append("eventTypeId", eventType);

      if (description) {
        formData.append("description", description);
      }
    }

    if (
      ((post?.category && post?.category?.type === "knowledge-base") ||
        (post?.category && post?.category?.type === "files") ||
        (post?.category && post?.category?.type === "event") ||
        (post?.category && post?.category?.type === "forum-posts") ||
        (post?.category && post?.category?.type === "educational-training")) &&
      ((attachmentFiles && attachmentFiles.length > 0) ||
        (deletedAttachmentIds && deletedAttachmentIds.length > 0))
    ) {
      attachmentFiles.forEach((file) => {
        formData.append("attachments", file);
      });

      deletedAttachmentIds.forEach((id) => {
        formData.append("deletedAttachmentIds[]", id);
      });
    }

    if (
      post?.category &&
      post?.category?.type === "listing" &&
      ((photographs && photographs.length > 0) ||
        (deletedPhotographIds && deletedPhotographIds.length > 0))
    ) {
      photographs.forEach((file) => {
        formData.append("photographs", file);
      });

      deletedPhotographIds.forEach((id) => {
        formData.append("deletedPhotographIds[]", id);
      });
    }

    if (deletedVideoIds && deletedVideoIds.length > 0) {
      deletedVideoIds.forEach((id) => {
        formData.append("deletedVideoIds[]", id);
      });
    }

    if (
      (userRole.includes("brokerage") || userRole.includes("reos")) &&
      videoFiles &&
      videoFiles.length > 0
    ) {
      videoFiles.forEach((file) => {
        formData.append("videos", file);
      });
    }

    const result = await editPost({
      tierId: post?.tier?._id,
      postId: post?._id,
      formData,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        refetchPosts?.();
        resetForm();
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error editing post: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const resetForm = useCallback((newPost = {}) => {
    setEditedPostTitle(newPost?.postTitle || "");
    setEventType(newPost?.eventType?._id || "");
    setEditorContent(newPost?.content || "");
    setDescription(newPost?.description || "");
    setAttachmentFiles([]);
    setExistingAttachments(newPost?.attachments || []);
    setDeletedAttachmentIds([]);
    setPhotographs([]);
    setExistingPhotographs(newPost?.photographs || []);
    setExistingVideos(newPost?.videos || []);
    setDeletedVideoIds([]);
    setDeletedPhotographIds([]);
    setCurrentImage(newPost?.image?.url || "");
    setTags(newPost?.tags || []);
    setListingData({
      address: {
        suit: newPost?.listingDetails?.address?.suit || "",
        street: newPost?.listingDetails?.address?.street || "",
        city: newPost?.listingDetails?.address?.city || "",
        state: newPost?.listingDetails?.address?.state || "",
        country: newPost?.listingDetails?.address?.country || "",
      },
      listPrice: newPost?.listingDetails?.listPrice || "",
      listingDate: newPost?.listingDetails?.listingDate || "",
      bedrooms: newPost?.listingDetails?.bedrooms || "",
      bathrooms: newPost?.listingDetails?.bathrooms || "",
      totalSqft: newPost?.listingDetails?.totalSqft || "",
      finishedSqft: newPost?.listingDetails?.finishedSqft || "",
      area: newPost?.listingDetails?.area || "",
      subarea: newPost?.listingDetails?.subarea || "",
      writeup: newPost?.listingDetails?.writeup || "",
      comments: newPost?.listingDetails?.comments || "",
      links: newPost?.listingDetails?.links || [],
    });
    setEventData({
      address: {
        suit: newPost?.eventDetails?.address?.suit || "",
        street: newPost?.eventDetails?.address?.street || "",
        city: newPost?.eventDetails?.address?.city || "",
        state: newPost?.eventDetails?.address?.state || "",
        country: newPost?.eventDetails?.address?.country || "",
      },
      isOnline: newPost?.eventDetails?.isOnline || false,
      link: newPost?.eventDetails?.link || "",
      eventDate:
        dayjs(newPost?.eventDetails?.eventDate).local().toISOString() || "",
      eventEndDate: newPost?.eventDetails?.eventEndDate
        ? dayjs(newPost?.eventDetails?.eventEndDate).local().toISOString()
        : null,
      recurrence: {
        frequency: newPost?.eventDetails?.recurrence?.frequency || "",
        interval: newPost?.eventDetails?.recurrence?.interval || 1,
        endDate: newPost?.eventDetails?.recurrence?.endDate
          ? dayjs(newPost?.eventDetails?.recurrence?.endDate)
              .local()
              .toISOString()
          : null,
      },
    });
  }, []);

  useEffect(() => {
    if (open) {
      resetForm(post);
    }
  }, [post, open, resetForm]);

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB limit

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      event.preventDefault();
      event.stopPropagation();

      const file = event.target.files[0];
      if (file && file.size > MAX_IMAGE_SIZE) {
        setNotification({
          open: true,
          message: "File size exceeds the limit of 5MB",
        });
        return;
      }
      if (file) {
        setNewImageFile(file);
        const imageUrl = URL.createObjectURL(file);
        setCurrentImage(imageUrl);
      }
    }
  };

  const handleNewAttachmentChange = (event) => {
    const filesArray = Array.from(event.target.files);
    if (
      existingAttachments.length + attachmentFiles.length + filesArray.length >
      10
    ) {
      setNotification({
        open: true,
        message: "You can upload up to 10 attachments.",
      });
      return;
    }

    if (filesArray) {
      setAttachmentFiles((prev) => [...prev, ...filesArray]);
    }
  };

  const handleDeleteExistingAttachment = (attachmentId) => {
    setDeletedAttachmentIds((prev) => [...prev, attachmentId]);
    setExistingAttachments((prev) =>
      prev.filter((att) => att._id !== attachmentId)
    );
  };

  const handleRemoveNewAttachment = (indexToRemove) => {
    setAttachmentFiles((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleNewPhotographChange = (event) => {
    const filesArray = Array.from(event.target.files);
    if (
      existingPhotographs.length + photographs.length + filesArray.length >
      10
    ) {
      setNotification({
        open: true,
        message: "You can upload up to 10 photographs.",
      });
      return;
    }

    if (filesArray) {
      setPhotographs((prev) => [...prev, ...filesArray]);
    }
  };

  const handleDeleteExistingPhotograph = (photographId) => {
    setDeletedPhotographIds((prev) => [...prev, photographId]);
    setExistingPhotographs((prev) =>
      prev.filter((att) => att._id !== photographId)
    );
  };

  const handleRemoveNewPhotograph = (indexToRemove) => {
    setPhotographs((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleAddTag = () => {
    const newTag = currentTag.trim().toLowerCase();
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      setCurrentTag("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddTag();
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags(tags?.filter((tag) => tag !== tagToDelete));
  };

  const handleGenerateTags = () => {
    const generatedTags = generateTags(
      editedPostTitle,
      editorContent || description
    );
    const newUniqueTags = generatedTags.filter((tag) => !tags.includes(tag));
    setTags([...tags, ...newUniqueTags]);
  };

  const handleEditPostClose = () => {
    resetForm();
    onClose();
  };

  const handleVideoFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (existingVideos.length + videoFiles.length + newFiles.length > 5) {
      setNotification({
        open: true,
        message: "You can upload up to 5 videos.",
      });
      return;
    }

    if (newFiles) {
      setVideoFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveVideo = (indexToRemove) => {
    setVideoFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleRecordVideoChange = (event) => {
    setRecordVideo(event.target.checked);
    if (event.target.checked) {
      setUploadVideo(false);
    }
  };

  const handleUploadVideoChange = (event) => {
    setUploadVideo(event.target.checked);
    if (event.target.checked) {
      setRecordVideo(false);
    }
  };

  const handleDeleteExistingVideo = (videoId) => {
    setDeletedVideoIds((prev) => [...prev, videoId]);
    setExistingVideos((prev) => prev.filter((v) => v._id !== videoId));
  };

  const extractFileNameFromUrl = (url) => {
    if (!url) return;
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  };

  const VideoRecorderComponent = useMemo(
    () => (
      <RecordView
        onSave={(videoFile) => {
          setVideoFiles((prevFiles) => [...prevFiles, videoFile]);
        }}
      />
    ),
    []
  );

  return (
    <Dialog
      open={open}
      onClose={handleEditPostClose}
      fullWidth
      className="edit-post"
    >
      <DialogTitle>
        <Typography gutterBottom textAlign="center" className="dialog-title">Edit Post</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          className="edit-post-form"
          spacing={2}
          direction="column"
          justifyContent="center"
        >
          {(userRole.includes("brokerage") || userRole.includes("reos")) && (
            <Grid item container direction="row" alignItems="center">
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendNotifications}
                      onChange={(e) => setSendNotifications(e.target.checked)}
                      name="sendNotifications"
                      color="primary"
                    />
                  }
                  label="Send Notifications"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendEmails}
                      onChange={(e) => setSendEmails(e.target.checked)}
                      name="sendEmails"
                      color="primary"
                    />
                  }
                  label="Send Emails"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendTextMessages}
                      onChange={(e) => setSendTextMessages(e.target.checked)}
                      name="sendTextMessages"
                      color="primary"
                    />
                  }
                  label="Send Text Messages"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={override}
                      onChange={(e) => setOverride(e.target.checked)}
                      name="override"
                      color="primary"
                    />
                  }
                  label="Override Notification Settings"
                />
              </Grid>
            </Grid>
          )}
          {post?.category?.type === "listing" && (
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label htmlFor={photographsEditInputId}>
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ textTransform: "none", mb: 2 }}
                  >
                    {`${
                      photographs && photographs?.length > 0
                        ? "Add More"
                        : "Upload"
                    } Photographs (Optional)`}
                  </Button>
                  <input
                    id={photographsEditInputId}
                    type="file"
                    hidden
                    multiple
                    accept=".jpg,.png,.jpeg"
                    onChange={(event) => handleNewPhotographChange(event)}
                  />
                </label>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">Existing Photographs:</Typography>
                  {existingPhotographs &&
                    existingPhotographs?.map((photograph, index) => {
                      const urlParts =
                        photograph.url && photograph.url.split("/");
                      const photographName = urlParts[urlParts.length - 1];

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2">
                            {photographName}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleDeleteExistingPhotograph(photograph._id)
                            }
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      );
                    })}
                  {photographs && photographs?.length > 0 && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      New Photographs:
                    </Typography>
                  )}
                  {photographs &&
                    photographs?.map((file, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 1,
                        }}
                      >
                        <Typography variant="body2">{file.name}</Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveNewPhotograph(index)}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
                <Tooltip title="Allowed image types: jpg, png, jpeg.">
                  <IconButton>
                    <Info fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          )}
          {post?.category?.type !== "announcements" &&
            post?.category?.type !== "event" &&
            post?.category?.type !== "educational-training" &&
            post?.category?.type !== "knowledge-base" &&
            post?.category?.type !== "listing" && (
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor={imageEditInputId}>
                    <Button
                      variant="contained"
                      component="span"
                      sx={{ textTransform: "none" }}
                    >
                      Upload New Image (Optional)
                    </Button>
                    <input
                      id={imageEditInputId}
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(event) => handleFileChange(event)}
                    />
                  </label>
                  {newImageFile && (
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {newImageFile?.name || "No file chosen"}
                    </Typography>
                  )}
                  <Tooltip title="Allowed file types: jpg, png, jpeg. Max size: 5MB.">
                    <IconButton>
                      <Info fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  className={`image-upload-container ${
                    currentImage || newImageFile ? "active" : ""
                  }`}
                >
                  {currentImage && (
                    <Box>
                      <div className="crop-container">
                        <Cropper
                          image={currentImage}
                          crop={crop}
                          zoom={zoom}
                          aspect={16 / 9}
                          onCropChange={setCrop}
                          onCropComplete={handleImageChange}
                          onZoomChange={setZoom}
                        />
                      </div>
                      <div className="controls">
                        <input
                          id={imageRangeCroppingEditInputId}
                          type="range"
                          value={zoom}
                          min={0}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e) => {
                            setZoom(e.target.value);
                          }}
                          className="zoom-range"
                        />
                      </div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setCurrentImage(null);
                          setDeletedImage(true);
                        }}
                      >
                        Remove Image
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
          <Grid item>
            <TextField
              fullWidth
              required
              variant="outlined"
              margin="normal"
              placeholder="Post Title"
              label="Post Title"
              value={editedPostTitle}
              onChange={(e) => setEditedPostTitle(e.target.value)}
            />
          </Grid>
          {(userRole.includes("brokerage") || userRole.includes("reos")) && (
            <>
              {userRole.includes("brokeragesuperadmin") && (
                <>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showDateTime}
                            onChange={handleToggle}
                            name="showDateTime"
                            color="primary"
                          />
                        }
                        label="Show Date/Time Setting"
                      />
                    </Grid>
                    {showDateTime && (
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          required
                          label="Post Date/Time"
                          variant="outlined"
                          margin="normal"
                          placeholder="Create Date/Time"
                          value={newCreateDate}
                          onChange={(e) => setNewCreateDate(e.target.value)}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          const dialogContent = document.querySelector(
                            ".edit-post .MuiDialogContent-root"
                          ); // Adjust the selector as needed
                          if (dialogContent) {
                            dialogContent.scrollTop =
                              dialogContent.scrollHeight;
                          }
                        }}
                      >
                        Scroll to the bottom
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item container direction="row">
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={recordVideo}
                        onChange={handleRecordVideoChange}
                        name="recordVideo"
                        color="primary"
                      />
                    }
                    label="Record and add a video"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={uploadVideo}
                        onChange={handleUploadVideoChange}
                        name="uploadVideo"
                        color="primary"
                      />
                    }
                    label="Upload a video"
                  />
                </Grid>
              </Grid>
              {existingVideos.length > 0 && (
                <Grid item>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">Existing Videos:</Typography>
                    {existingVideos.map((video, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 1,
                        }}
                      >
                        <Typography variant="body2">
                          {extractFileNameFromUrl(video?.url)}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteExistingVideo(video?._id)}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
              {videoFiles.length > 0 && (
                <Grid item>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">Selected Videos:</Typography>
                    {videoFiles &&
                      videoFiles?.length > 0 &&
                      videoFiles?.map((file, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2">{file.name}</Typography>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveVideo(index)}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                </Grid>
              )}
              {uploadVideo && (
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label htmlFor="video-upload">
                      <Button
                        variant="contained"
                        component="span"
                        sx={{ textTransform: "none", mb: 2 }}
                      >
                        {`${
                          videoFiles && videoFiles?.length > 0
                            ? "Add More"
                            : "Upload"
                        } Videos (Optional)`}
                      </Button>
                      <input
                        id="video-upload"
                        type="file"
                        hidden
                        multiple
                        accept="video/*"
                        onChange={handleVideoFileChange}
                      />
                    </label>
                  </Box>
                </Grid>
              )}
              {recordVideo && (
                <Grid item className="record-view-container">
                  {VideoRecorderComponent}
                </Grid>
              )}
            </>
          )}

          {post?.category?.type === "event" && (
            <Grid item>
              <TextField
                fullWidth
                select
                required
                variant="outlined"
                margin="normal"
                value={eventType}
                onChange={handleEventTypeChange}
                SelectProps={{ native: true }}
              >
                <option value="" disabled>
                  Select Event Type
                </option>
                {eventTypes?.map((eventType) => (
                  <option key={eventType?._id} value={eventType?._id}>
                    {eventType?.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          )}
          {post?.category?.type === "event" && post?.recurrenceGroupId && (
            <Grid item>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="edit-option-label">
                  Event Edit Option
                </InputLabel>
                <Select
                  labelId="edit-option-label"
                  id="edit-option"
                  value={eventEditOption}
                  onChange={handleEventEditOptionChange}
                  label="Event Edit Option"
                >
                  <MenuItem value="current">
                    Edit Current Event Post Only
                  </MenuItem>
                  <MenuItem value="all">
                    Edit All Recurring Event Posts
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {(post?.category?.type === "event" ||
            post?.category?.type === "listing") && (
            <Grid item>
              <ReactQuill
                value={description}
                onChange={handleDescriptionChange}
                // onChange={(e) => setDescription(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
                label={`${
                  post?.category?.type === "listing"
                    ? "Listing Highlights"
                    : post?.category?.type === "event"
                    ? "Event Description"
                    : "Post content..."
                }`}
                placeholder={`${
                  post?.category?.type === "listing"
                    ? "Enter Listing Highlights (optional) - It is highly suggested these properties not be shown prior to getting full exposure to the potential buyer market. "
                    : post?.category?.type === "event"
                    ? "Enter Event Description (optional)"
                    : "Enter your post content below..."
                }`}
                multiline
                rows={5}
              />
              {contentLength > MAX_CONTENT_LENGTH ? (
                <Typography variant="body2" color="error">
                  {`Content Exceeded Max: ${contentLength}/${MAX_CONTENT_LENGTH} characters`}
                </Typography>
              ) : contentLength > MAX_CONTENT_LENGTH * 0.9 ? (
                <Typography variant="body2" color="darkorange">
                  {`Content Length Nearing Max: ${contentLength}/${MAX_CONTENT_LENGTH} characters`}
                </Typography>
              ) : null}
            </Grid>
          )}
          {post?.category &&
            (post?.category?.type === "knowledge-base" ||
              post?.category?.type === "files" ||
              post?.category?.type === "event" ||
              post?.category?.type === "forum-posts" ||
              post?.category?.type === "educational-training") && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%", // Ensure full width to maintain consistency
                    mt: 2,
                    p: 2, // Padding for better spacing around the content
                    bgcolor: "background.paper", // Background color for better focus on the section
                    borderRadius: "8px", // Rounded corners for modern look
                    boxShadow: 1, // Subtle shadow for depth
                  }}
                >
                  <label
                    htmlFor={attachmentsEditInputId}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        textTransform: "none",
                        mb: 1, // Margin bottom for spacing between button and note
                      }}
                    >
                      {attachmentFiles && attachmentFiles?.length > 0
                        ? "Add More"
                        : "Upload"}{" "}
                      Attachments (Optional)
                    </Button>
                    <input
                      id={attachmentsEditInputId}
                      type="file"
                      hidden
                      multiple
                      accept=".pdf,.txt,.doc,.docx,.jpg,.png,.jpeg,.xlsx,.xls,.pptx,.ppt,.mp4,.m4a"
                      onChange={(event) => handleNewAttachmentChange(event)}
                    />
                  </label>
                  <Box sx={{ width: "100%", mt: 1, mb: 0, pl: 2, pr: 2 }}>
                    {existingAttachments && existingAttachments?.length > 0 && (
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Existing Attachments:
                      </Typography>
                    )}
                    {existingAttachments &&
                      existingAttachments?.map((attachment, index) => {
                        const urlParts =
                          attachment &&
                          attachment?.url &&
                          attachment?.url?.split("/");
                        const attachmentName =
                          urlParts && urlParts[urlParts?.length - 1];

                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: 1,
                              m: 0, // More spacing for better separation
                              p: 0, // Padding inside each attachment entry for better touch area
                              bgcolor: "background.default", // Slightly different background for distinction
                              borderRadius: "4px", // Rounded edges for individual items
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ flexGrow: 1, mr: 1, mt: 0, mb: 0 }}
                            >
                              {attachmentName}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() =>
                                handleDeleteExistingAttachment(attachment?._id)
                              }
                              sx={{ color: "error.main" }}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </Box>
                        );
                      })}
                    {attachmentFiles && attachmentFiles?.length > 0 && (
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, fontWeight: "bold" }}
                      >
                        New Attachments:
                      </Typography>
                    )}
                    {attachmentFiles &&
                      attachmentFiles.map((file, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1,
                            m: 0,
                            p: 0,
                            bgcolor: "background.default",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ flexGrow: 1, mr: 1 }}
                          >
                            {file.name}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveNewAttachment(index)}
                            sx={{ color: "error.main" }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                  </Box>
                  <Tooltip
                    title="Allowed file types: pdf, txt, doc, docs, jpg, png, jpeg, pptx, ppt, xls, xlsx."
                    placement="right"
                  >
                    <IconButton>
                      <Info fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            )}
          {post?.category?.type !== "event" &&
            post?.category?.type !== "listing" && (
              <Grid item>
                <ReactQuill
                  value={editorContent}
                  onChange={handleEditorChange}
                  modules={modules}
                  placeholder={`${
                    post?.category?.type === "listing"
                      ? "Enter Listing Highlights (optional) - It is highly suggested these properties not be shown prior to getting full exposure to the potential buyer market. "
                      : post?.category?.type === "event"
                      ? "Enter Event Description (optional)"
                      : "Enter your post content below..."
                  }`}
                />
                <Typography
                  variant="body2"
                  color={
                    contentLength > MAX_CONTENT_LENGTH ? "error" : "textPrimary"
                  }
                >
                  {`Content length: ${contentLength}/${MAX_CONTENT_LENGTH} characters`}
                </Typography>
              </Grid>
            )}
          {post?.category?.type === "event" && (
            <>
              <Grid item>
                <Typography
                  label="Address"
                  name="addressCity"
                  variant="outlined"
                  margin="normal"
                >
                  Address
                </Typography>
                <TextField
                  label="Unit"
                  name="address.suit"
                  value={eventData.address.suit}
                  onChange={handleEventDataChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Street"
                  name="address.street"
                  value={eventData.address.street}
                  onChange={handleEventDataChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="City"
                  name="address.city"
                  value={eventData.address.city}
                  onChange={handleEventDataChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Prov/State"
                  name="address.state"
                  value={eventData.address.state}
                  onChange={handleEventDataChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Country"
                  name="address.country"
                  value={eventData.address.country}
                  onChange={handleEventDataChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={eventData.isOnline}
                      onChange={handleOnlineChange}
                      name="isOnline"
                      color="primary"
                    />
                  }
                  label="Is Online Event"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Event Link"
                  name="link"
                  value={eventData.link}
                  onChange={handleEventDataChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      label="Event Start Date"
                      value={dayjs(eventData.eventDate)}
                      onChange={(newValue) =>
                        setEventData({ ...eventData, eventDate: newValue })
                      }
                      disableFuture={false}
                      showDaysOutsideCurrentMonth
                    />
                    <DateTimePicker
                      label="Event End Date"
                      value={
                        eventData.eventEndDate
                          ? dayjs(eventData.eventEndDate)
                          : null
                      }
                      onChange={(newValue) =>
                        setEventData({ ...eventData, eventEndDate: newValue })
                      }
                      disableFuture={false}
                      showDaysOutsideCurrentMonth
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </>
          )}
          {post?.category?.type === "listing" && (
            <>
              <Grid item>
                <Typography
                  label="Address"
                  name="addressCity"
                  variant="outlined"
                  margin="normal"
                >
                  Address
                </Typography>
                <TextField
                  label="Unit"
                  name="address.suit"
                  value={listingData.address.suit}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Street"
                  name="address.street"
                  value={listingData.address.street}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="City"
                  name="address.city"
                  value={listingData.address.city}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Prov/State"
                  name="address.state"
                  value={listingData.address.state}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Country"
                  name="address.country"
                  value={listingData.address.country}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="List Price"
                  name="listPrice"
                  type="number"
                  value={listingData.listPrice}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Listing Date"
                      value={dayjs(listingData.listingDate)}
                      onChange={handleListingDateChange}
                      format="LL"
                      disableFuture={false}
                      showDaysOutsideCurrentMonth
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Grid item sx={{ color: "red" }}>
                  {error.startDateError.hasError &&
                    error.startDateError.message}
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label="Bedrooms"
                  name="bedrooms"
                  type="number"
                  value={listingData.bedrooms}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Bathrooms"
                  name="bathrooms"
                  type="number"
                  value={listingData.bathrooms}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Total Sqft"
                  name="totalSqft"
                  type="number"
                  value={listingData.totalSqft}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Finished Sqft"
                  name="finishedSqft"
                  type="number"
                  value={listingData.finishedSqft}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Lot Sqft"
                  name="lotSqft"
                  type="number"
                  value={listingData.lotSqft}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Lot Acres"
                  name="lotAcres"
                  type="number"
                  value={listingData.lotAcres}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Area"
                  name="area"
                  value={listingData.area}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Subarea"
                  name="subarea"
                  value={listingData.subarea}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Link Name"
                  name="linkName"
                  value={
                    listingData.links.length > 0
                      ? listingData.links[0].linkName
                      : ""
                  }
                  onChange={(e) => {
                    const updatedLinks = [...listingData.links];
                    updatedLinks[0] = {
                      ...updatedLinks[0],
                      linkName: e.target.value,
                    };
                    setListingData({
                      ...listingData,
                      links: updatedLinks,
                    });
                  }}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  label="Link URL"
                  name="linkUrl"
                  value={
                    listingData.links.length > 0
                      ? listingData.links[0].linkUrl
                      : ""
                  }
                  onChange={(e) => {
                    const updatedLinks = [...listingData.links];
                    updatedLinks[0] = {
                      ...updatedLinks[0],
                      linkUrl: e.target.value,
                    };
                    setListingData({ ...listingData, links: updatedLinks });
                  }}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  name="writeup"
                  value={listingData.writeup}
                  onChange={handleListingChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Write Up"
                  placeholder="Enter Listing Write-up (optional)"
                  multiline
                  rows={15}
                />
                <span>Characters: {listingData.writeup.length}</span>
              </Grid>
            </>
          )}
          <Grid item>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                label="Tags"
                variant="outlined"
                placeholder="Enter a tag"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value.toLowerCase())}
                onKeyDown={handleKeyDown}
                fullWidth
              />
              {isMobile && (
                <Button variant="contained" onClick={handleAddTag}>
                  Add
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleGenerateTags}
                sx={{ mt: 1 }}
              >
                Auto Generate Tags
              </Button>
            </Box>
            <Typography variant="caption">
              Press 'Enter' or use the 'Add' button to include a tag.
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
              {tags?.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(tag)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
        <DialogActions>
          <Grid item>
            <Button
              onClick={onClose}
              disabled={isLoading}
              color="primary"
              type="button"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleEditSubmit}
              disabled={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {isLoading ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Grid>
        </DialogActions>
      </DialogContent>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default memo(EditPost);
