import { AuthContext } from "../../../context/AuthContext";
import { SidebarContext } from "../../../context/SidebarContext";
import { TabPanel } from "../../helpers/utils";
import MemberBillingTab from "./MemberBillingTab";
import MemberCommentsTab from "./MemberCommentsTab";
import MemberDataTab from "./MemberDataTab";
import MemberNotificationsTab from "./MemberNotificationsTab";
import MemberPostsTab from "./MemberPostsTab";
import MemberQRTab from "./MemberQRTab";
import MemberTransactionsTab from "./MemberTransactionsTab";
import { Tabs, Tab, Paper } from "@mui/material";
import React, { useContext, useEffect } from "react";

const MemberProfileTabs = ({ member, tierId }) => {
  const { userId, accountType } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const { sidebarOpen } = useContext(SidebarContext);

  useEffect(() => {
    const savedTabIndex = localStorage.getItem("mmpt");
    if (savedTabIndex !== null) {
      setValue(parseInt(savedTabIndex, 10));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("mmpt", newValue);
  };

  return (
    <div className="member-profile-tabs">
      <Tabs
        value={value}
        onChange={handleChange}
        className={`${
          !sidebarOpen
            ? "member-profile-tabs-header sidebar-opened"
            : "member-profile-tabs-header"
        }`}
      >
        <Tab label="About" />
        <Tab label="Posts" />
        <Tab label="Comments" />
        <Tab label="QR Codes" />
        <Tab label="Notifications" />

        {member.user === userId ||
        ["brokerage", "reosadmin"].includes(accountType) ? (
          <Tab label="Transactions" />
        ) : null}

        {member.user === userId && member?.isBillingActive ? (
          <Tab label="Billing" />
        ) : null}
      </Tabs>
      <div className="member-profile-tabs-body">
        <Paper
          elevation={3}
          style={{
            alignItems: "left",
            margin: 10,
            padding: 3,
          }}
        >
          <TabPanel value={value} index={0}>
            <MemberDataTab member={member} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MemberPostsTab member={member} tierId={tierId} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MemberCommentsTab member={member} tierId={tierId} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MemberQRTab member={member} tierId={tierId} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <MemberNotificationsTab member={member} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <MemberTransactionsTab member={member} tierId={tierId} />
          </TabPanel>
          {member?.isBillingActive ? (
            <TabPanel value={value} index={6}>
              <MemberBillingTab
                member={member}
                tierId={tierId}
                sidebarOpen={sidebarOpen}
              />
            </TabPanel>
          ) : null}
        </Paper>
      </div>
    </div>
  );
};

export default MemberProfileTabs;
