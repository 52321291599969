import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCategoryByIdQuery } from "../../../services/category";
import { categoryIcon } from "../../sidebar/sidebarHelpers";

import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  CircularProgress,
  Divider,
} from "@mui/material";
import { BackButton } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CategoryItemPage = () => {
  const { categoryItemId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: category,
    error,
    isLoading,
  } = useGetCategoryByIdQuery(categoryItemId, {
    skip: !categoryItemId,
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!category) {
    return (
      <Typography variant="h4" gutterBottom>
        No Category Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;
  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
      </Box>
      <Card>
        <Typography sx={{ mt: 3 }} variant="h4" align="center" gutterBottom>
          Category Details
        </Typography>
        <CardContent>
          <Table className="user-table">
            <TableBody>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Category Name</b>
                </TableCell>
                <TableCell>{category?.name || ""}</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Category Tier Level</b>
                </TableCell>
                <TableCell>{category?.tier?.level || ""}</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Type</b>
                </TableCell>
                <TableCell>{category?.type || ""}</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Path</b>
                </TableCell>
                <TableCell>{category?.path || ""}</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Icon</b>
                </TableCell>
                <TableCell>
                  {category?.icon && (
                    <>
                      {category?.icon} {categoryIcon(category?.icon)}
                    </>
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Category Group</b>
                </TableCell>
                <TableCell>{category?.group?.name || ""}</TableCell>
              </TableRow>
              <TableRow className="table-row">
                <TableCell component="th" scope="row" className="label">
                  <b>Group Path</b>
                </TableCell>
                <TableCell>{category?.group?.path || ""}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Divider sx={{ my: 2 }} />{" "}
          {/* You can adjust the Divider's styling as needed */}
          {/* New Section */}
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Category General Types
            </Typography>
            <Table className="user-table">
              <TableBody>
                <TableRow>
                  <TableCell className="label">Is Widgetable</TableCell>
                  <TableCell>{category?.isWidgetable ? "Yes" : "No"}</TableCell>
                  <TableCell className="label">Is Event</TableCell>
                  <TableCell>{category?.isEvent ? "Yes" : "No"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Is Member List</TableCell>
                  <TableCell>{category?.isMemberList ? "Yes" : "No"}</TableCell>
                  <TableCell className="label">Is Home Page</TableCell>
                  <TableCell>{category?.isHomePage ? "Yes" : "No"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Is Chat</TableCell>
                  <TableCell>{category?.isChat ? "Yes" : "No"}</TableCell>
                  <TableCell className="label">Is File System</TableCell>
                  <TableCell>{category?.isFileSystem ? "Yes" : "No"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Is Listings</TableCell>
                  <TableCell>{category?.isListings ? "Yes" : "No"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Category Options
            </Typography>
            <Table className="user-table">
              <TableBody>
                <TableRow>
                  <TableCell className="label">Allow Tags</TableCell>
                  <TableCell>{category?.allowTags ? "Yes" : "No"}</TableCell>
                  <TableCell className="label">Use WYSIWYG</TableCell>
                  <TableCell>{category?.useWYSIWYG ? "Yes" : "No"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Has Feature Picture</TableCell>
                  <TableCell>
                    {category?.hasFeaturePicture ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className="label">
                    Allow Acknowledgements
                  </TableCell>
                  <TableCell>
                    {category?.allowAcknowledgements ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Allow Attachments</TableCell>
                  <TableCell>
                    {category?.allowAttachments ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className="label">Allow Comments</TableCell>
                  <TableCell>
                    {category?.allowComments ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="label">Allow Likes</TableCell>
                  <TableCell>{category?.allowLikes ? "Yes" : "No"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default CategoryItemPage;
