import { AuthContext } from "../../context/AuthContext";
import { useLoginUserMutation } from "../../services/auth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  TextField,
  Button,
  Typography,
  Snackbar,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const { handleLogin, customSystemName, isCustomSystem, updateCustomSystem } =
    useContext(AuthContext);
  const [notification, setNotification] = useState({
    message: "",
    severity: "info",
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const [loginUserAction, { isLoading }] = useLoginUserMutation();

  const handleLoginAttempt = async (e) => {
    e.preventDefault();

    let isValid = true;
    let newErrors = {
      email: "",
      password: "",
    };

    const trimmedEmail = email.trim();
    if (!isValidEmail(trimmedEmail)) {
      newErrors.email = "Invalid email format";
      setNotification({
        message: "Please correct the highlighted errors.",
        severity: "warning",
      });
      isValid = false;
    }

    if (!isValidPassword(password)) {
      newErrors.password = "Password must be at least 8 characters long";
      setNotification({
        message: "Please correct the highlighted errors.",
        severity: "warning",
      });
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
      setNotification({
        message: "Please correct the highlighted errors.",
        severity: "warning",
      });
      return;
    }

    try {
      const result = await loginUserAction({
        email: trimmedEmail,
        password,
      }).unwrap();

      if (result?.token) {
        handleLogin(result);
        setNotification({ message: "Login successful!", severity: "success" });
        setTimeout(() => {
          //set the customSystemName
          if (result.customSystemName && result.customSystemName !== "")
            updateCustomSystem(true, result.customSystemName);

          if (result.accountType === "reosadmin") {
            navigate("/user-management");
          } else {
            navigate("/home");
          }
        }, 500);
      }
    } catch (err) {
      if (err?.data?.msg) {
        setNotification({ message: err?.data?.msg, severity: "error" });
      } else {
        setNotification({ message: "An error occurred", severity: "error" });
      }
      if (err?.data?.msg === "TokenExpired") {
        setNotification({
          message: "The Token has Expired",
          severity: "error",
        });
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      }
    }
  };

  let welcomeMessage = "Login";
  let welcomeMessageSub = "Powered By RealtyOS";

  if (customSystemName === "nsc") {
    welcomeMessage = `Welcome to the Unofficial NSC Connector`;
    welcomeMessageSub = `Provided by REACirc`;
  }

  return (
    <div
      className={`login-page 
      ${customSystemName === "nsc" ? "conference" : ""}`}
    >
      <div
        style={{
          backgroundColor: "var(--bg-color-opt2)",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "140px",
        }}
      >
        {/* If customized display a customized system  */}
        {isCustomSystem && (
          <>
            <img
              src={`/imgs/customizations/${customSystemName}.png`}
              alt={customSystemName}
              style={{
                width: "300px",
                height: "auto",
                objectFit: "contain",
                margin: "30px",
              }}
            />
          </>
        )}
        <Typography variant="h5" sx={{ mt: 3, fontWeight: 600 }}>
          {welcomeMessage}
        </Typography>
        <Typography variant="h7" sx={{ mt: 3, fontWeight: 300 }}>
          {welcomeMessageSub}
        </Typography>
        <form onSubmit={handleLoginAttempt}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={Boolean(errors.email)}
            helperText={errors.email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors((prev) => ({ ...prev, email: "" }));
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            error={Boolean(errors.password)}
            helperText={errors.password}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrors((prev) => ({ ...prev, password: "" }));
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  size="small"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
          <Button
            color="secondary"
            size="small"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Log In"}
          </Button>
        </form>
        <Snackbar
          open={Boolean(notification.message)}
          autoHideDuration={3000}
          message={notification?.message || "An error occurred"}
          onClose={() => setNotification({ message: "", severity: "info" })}
        />
      </div>
    </div>
  );
}

export default Login;
