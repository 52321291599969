import { isTokenExpired } from "../services/custom-hooks/helpUtils";
import { messagesApi } from "../services/messages";
import { notificationsApi } from "../services/notifications";
import { BrokerageEcoSystemContext } from "./BrokerageEcoSystemContext";
import React, { createContext, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { setBrokerageEcoSysId } = useContext(BrokerageEcoSystemContext);
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [userId, setUserId] = useState(() => localStorage.getItem("userId"));

  const [userImage, setUserImage] = useState(() =>
    localStorage.getItem("userImage")
  );
  const [userName, setUserName] = useState(() =>
    localStorage.getItem("userName")
  );
  const [userRole, setUserRole] = useState(() =>
    localStorage.getItem("userRole")
  );
  const [userPermissions, setUserPermissions] = useState(() => {
    const storedPermissions = localStorage.getItem("userPermissions");
    return storedPermissions ? JSON.parse(storedPermissions) : [];
  });
  const [userRoleTiers, setUserRoleTiers] = useState(() => {
    const storedRoleTiers = localStorage.getItem("userRoleTiers");
    return storedRoleTiers ? JSON.parse(storedRoleTiers) : [];
  });
  const [brokerageId, setBrokerageId] = useState(() =>
    localStorage.getItem("brokerageId")
  );
  const [brokerageTierId, setBrokerageTierId] = useState(() =>
    localStorage.getItem("brokerageTierId")
  );
  const [isCustomSystem, setIsCustomSystem] = useState(() =>
    localStorage.getItem("isCustomSystem")
  );

  const [customSystemName, setCustomSystemName] = useState(() =>
    localStorage.getItem("customSystemName")
  );
  const [accountType, setAccountType] = useState(() =>
    localStorage.getItem("accountType")
  );
  const [isTokenReady, setIsTokenReady] = useState(false);

  const dispatch = useDispatch();

  const fetchNotifications = () => {
    dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
  };
  const fetchMessages = () => {
    dispatch(messagesApi.util.invalidateTags(["Messages"]));
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      setIsTokenReady(true);
    } else {
      setIsTokenReady(false);
    }
    if (userId) localStorage.setItem("userId", userId);
    if (userImage) localStorage.setItem("userImage", userImage);
    if (brokerageId) localStorage.setItem("brokerageId", brokerageId);
    if (brokerageTierId)
      localStorage.setItem("brokerageTierId", brokerageTierId);
    if (customSystemName)
      localStorage.setItem("customSystemName", customSystemName);
    if (isCustomSystem) localStorage.setItem("isCustomSystem", isCustomSystem);
    if (accountType) localStorage.setItem("accountType", accountType);
    if (userName) localStorage.setItem("userName", userName);
    if (userRole) localStorage.setItem("userRole", userRole);
    if (userPermissions)
      localStorage.setItem("userPermissions", JSON.stringify(userPermissions));
    if (userRoleTiers)
      localStorage.setItem("userRoleTiers", JSON.stringify(userRoleTiers));
  }, [
    token,
    userId,
    brokerageId,
    brokerageTierId,
    customSystemName,
    isCustomSystem,
    accountType,
    userName,
    userRole,
    userPermissions,
    userRoleTiers,
    userImage,
  ]);

  const updateCustomSystem = (isCustom, name) => {
    setIsCustomSystem(isCustom);

    setCustomSystemName(name);
  };

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
    setUserId(null);
    setUserImage(null);
    setBrokerageId(null);
    setBrokerageTierId(null);
    setAccountType(null);
    setUserName(null);
    setUserRole(null);
    setUserPermissions(null);
    setUserRoleTiers(null);
    setCustomSystemName(null);
    setIsCustomSystem(null);

    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userImage");
    localStorage.removeItem("brokerageId");
    localStorage.removeItem("brokerageTierId");
    localStorage.removeItem("customSystemName");
    localStorage.removeItem("accountType");
    localStorage.removeItem("userName");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userPermissions");
    localStorage.removeItem("userRoleTiers");
    localStorage.removeItem("isCustomSystem");
    localStorage.removeItem("abt");
    localStorage.removeItem("ast");
    localStorage.removeItem("apt");
    localStorage.removeItem("mmpt");
  };

  useEffect(() => {
    if (token && isTokenExpired(token)) {
      handleLogout();
    }
  }, [token]);

  const handleLogin = (data) => {
    const {
      token,
      userId,
      userImage,
      brokerageId,
      brokerageTierId,
      customSystemName,
      accountType,
      userName,
      userRole,
      userPermissions,
      userRoleTiers,
    } = data;
    setToken(token);
    setUserId(userId);
    setUserImage(userImage);
    setBrokerageId(brokerageId);
    setBrokerageTierId(brokerageTierId);
    setCustomSystemName(customSystemName);
    setBrokerageEcoSysId(brokerageId || "");
    setAccountType(accountType);
    setUserName(userName);
    setUserRole(userRole);
    setUserPermissions(JSON.stringify(userPermissions));
    setUserRoleTiers(JSON.stringify(userRoleTiers.map(String)));

    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
    localStorage.setItem("userImage", userImage);
    localStorage.setItem("brokerageId", brokerageId);
    localStorage.setItem("brokerageTierId", brokerageTierId);
    localStorage.setItem("customSystemName", customSystemName);
    localStorage.setItem("accountType", accountType);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userRole", userRole);
    localStorage.setItem("userPermissions", JSON.stringify(userPermissions));
    localStorage.setItem("userRoleTiers", JSON.stringify(userRoleTiers));

    fetchNotifications();
    fetchMessages();
  };

  const updateUserImage = (newUserImage) => {
    setUserImage(newUserImage);
    localStorage.setItem("userImage", newUserImage);
  };

  const isAdmin = () => accountType === "reosadmin";
  const isMember = () => accountType === "member";
  const isBrokerage = () => accountType === "brokerage";

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        userImage,
        brokerageId,
        brokerageTierId,
        accountType,
        userName,
        userRole,
        userPermissions,
        userRoleTiers,
        isTokenReady,
        handleLogin,
        handleLogout,
        updateUserImage,
        isAdmin,
        isMember,
        isBrokerage,
        isCustomSystem,
        customSystemName,
        updateCustomSystem,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};