import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useAssignGroupRoleMutation } from "../../../../../services/group-role";
import NotificationSnackbar from "../../../../helpers/notification-snackbar";

const AssignGroupRoleDialog = ({
  groupId,
  open,
  onClose,
  preselectedGroupRole,
  preselectedGroupMember,
  groupRoles,
  groupMembers,
  action = "",
}) => {
  const [selectedGroupRoleId, setSelectedGroupRoleId] = useState(
    action === "thisRoleToMember" && preselectedGroupRole
      ? preselectedGroupRole?._id
      : ""
  );
  const [selectedMemberId, setSelectedMemberId] = useState(
    action === "thisMemberToGroupRole" && preselectedGroupMember
      ? preselectedGroupMember
      : ""
  );
  const [assignGroupRole, { isLoading }] = useAssignGroupRoleMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (action === "thisRoleToMember" && preselectedGroupRole) {
      setSelectedGroupRoleId(preselectedGroupRole?._id);
    }
  }, [action, preselectedGroupRole]);

  const handleAssign = async () => {
    if (!selectedGroupRoleId || !selectedMemberId) {
      setNotification({
        open: true,
        message: "Please, choose the group role and/or select the member.",
      });

      return;
    }

    const result = await assignGroupRole({
      groupId,
      memberId: selectedMemberId,
      groupRoleId: selectedGroupRoleId,
    });
    if (result) {
      setNotification({
        open: true,
        message: `Error assigning the group role: ${result?.data?.msg}`,
      });
      setTimeout(() => {
        setSelectedGroupRoleId("");
        setSelectedMemberId("");
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error assigning the group role: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const isGroupRoleId = selectedGroupRoleId || preselectedGroupRole;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Assign Group Role</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="member-label">Member</InputLabel>
          <Select
            labelId="member-label"
            id="selectedMemberId"
            value={selectedMemberId}
            onChange={(e) => setSelectedMemberId(e.target.value)}
            fullWidth
            required
          >
            {groupMembers &&
              groupMembers.length > 0 &&
              groupMembers.map((groupMember) => (
                <MenuItem
                  key={groupMember?._id}
                  value={groupMember?.member?._id}
                >
                  {groupMember?.member?.user?.firstName}{" "}
                  {groupMember?.member?.user?.lastName}{" "}
                  {groupMember?.member?.user?.email}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {preselectedGroupRole ? (
          <TextField
            fullWidth
            label="Selected Group Role"
            variant="outlined"
            margin="normal"
            value={`${preselectedGroupRole?.name}`}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={selectedGroupRoleId}
              label="Role"
              onChange={(e) => setSelectedGroupRoleId(e.target.value)}
              fullWidth
              required
            >
              {groupRoles &&
                groupRoles.length > 0 &&
                groupRoles?.map((groupRole) => (
                  <MenuItem key={groupRole?._id} value={groupRole?._id}>
                    {groupRole?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleAssign}
          disabled={isLoading || !isGroupRoleId || !selectedMemberId}
        >
          {isLoading ? "Assigning..." : "Assign"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignGroupRoleDialog;
