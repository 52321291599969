import React, { useState, useContext } from "react";
import { useUpdateImageMutation } from "../../../services/user";
import { useChangePasswordMutation } from "../../../services/password";
import { AuthContext } from "../../../context/AuthContext";

import {
  Typography,
  CircularProgress,
  Button,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import PasswordChangeDialog from "../../account-profiles/PasswordChangeDialog";
import EmailChangeDialog from "../../account-profiles/EmailChangeDialog";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { capitalizeFirstLetter } from "../../helpers/utils";

function UserAccount({ user, refetch, isLoading, isError }) {
  const { updateUserImage } = useContext(AuthContext);
  const [changePassword] = useChangePasswordMutation();
  // eslint-disable-next-line
  const [oldPassword, setOldPassword] = useState("");
  // eslint-disable-next-line
  const [newPassword, setNewPassword] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [updateImage, { isLoading: isUpdatingImage }] =
    useUpdateImageMutation();

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await updateImage({ userId: user?._id, file });
        if (response.error) {
          throw response.error;
        }
        if (response?.data) {
          setNotification({
            open: true,
            message: "Image updated successfully!",
          });
          refetch?.();
          setTimeout(() => {
            updateUserImage(response?.data?.image?.url);
          }, 100);
        }
      } catch (error) {
        setNotification({
          open: true,
          message: `Failed to update image: ${error?.data?.msg}`,
        });
      }
    }
  };
  const formattedFirstName =
    user && user?.firstName ? capitalizeFirstLetter(user?.firstName) : "";
  const formattedLastName =
    user && user?.lastName ? capitalizeFirstLetter(user?.lastName) : "";

  const initials =
    (formattedFirstName?.[0] || "") + (formattedLastName?.[0] || "");

  const [openDialog, setOpenDialog] = useState(false);
  const [openEmailChangeDialog, setOpenEmailChangeDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenEmailChangeDialog = () => {
    setOpenEmailChangeDialog(true);
  };

  const handleCloseEmailChangeDialog = () => {
    setOpenEmailChangeDialog(false);
  };

  const handleChangePassword = async (oldPassword, newPassword) => {
    if (!(user && user?._id)) {
      setNotification({
        open: true,
        message: `There is no user data`,
      });
      return;
    }
    const result = await changePassword({
      userId: user?._id,
      oldPassword,
      newPassword,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setOldPassword("");
      setNewPassword("");
      setOpenDialog(false);
    } else {
      setNotification({
        open: true,
        message: `Failed to update password: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleEmailChangeCancel = () => {
    handleCloseEmailChangeDialog();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading || isUpdatingImage) return <CircularProgress />;

  return (
    <div className="user-account-profile">
      {!isError && (
        <>
          <div className="profile-edit-form-header">
            <div
              className="profile-edit-form-image-block"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="upload-image-file"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="upload-image-file">
                <Tooltip title="Upload Photo" arrow>
                  <IconButton color="primary" component="span">
                    <Avatar
                      alt={initials}
                      src={user?.image?.url}
                      sx={{ width: 100, height: 100 }}
                    >
                      {!user?.image?.url && initials}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </label>
              <Button
                variant="text"
                component="span"
                onClick={() =>
                  document.getElementById("upload-image-file").click()
                }
              >
                {user?.image?.url ? "Change Photo" : "Add Photo"}
              </Button>
            </div>
            <Typography
              variant="h4"
              gutterBottom
              className="profile-edit-form-title"
              sx={{ p: 1 }}
            >
              {user?.accountType?.toUpperCase()} User Profile
            </Typography>
          </div>
          <div className="profile-edit-form-fields">
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>Email:</strong> {user?.email || user?.email}
            </Typography>
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>isEmailPrivate:</strong>{" "}
              {user?.isEmailPrivate ? "Yes" : "No"}
            </Typography>
            {user?.isEmailPrivate && (
              <Typography variant="h6" sx={{ p: 1 }}>
                <strong>Public Email:</strong>{" "}
                {user?.emailPublic || user?.email}
              </Typography>
            )}
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>Account Type: </strong> {user?.accountType || ""}
            </Typography>
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>User Name:</strong>{" "}
              {(formattedFirstName || "") + " " + (formattedLastName || "")}
            </Typography>
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>Last Name:</strong> {user?.lastName}
            </Typography>
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>Default Brokerage: </strong>
              {user?.member?.brokerage?.officeName}
            </Typography>
            {user?.member?.brokerages?.length > 0 ? (
              user.member.brokerages.map((brokerage, index) => (
                <Typography variant="h6" sx={{ p: 1 }} key={index}>
                  <strong>Brokerage Office:</strong> {brokerage.officeName}
                </Typography>
              ))
            ) : (
              <Typography variant="h6" sx={{ p: 1 }}>
                <strong>Brokerage Office:</strong> None
              </Typography>
            )}
            <Typography variant="h6" sx={{ p: 1 }}>
              <strong>Status: </strong>
              {user?.isArchived ? "archived" : "active"}
            </Typography>
            <Typography variant="body1" sx={{ p: 1 }}>
              Account Created: {new Date(user?.accountCreated).toLocaleString()}{" "}
            </Typography>
            <Typography variant="body1" sx={{ p: 1 }}>
              Last Login: {new Date(user?.lastLogin).toLocaleString()}
            </Typography>
            <Typography variant="body1" sx={{ p: 1 }}>
              Last Failed Login:{" "}
              {user?.lastFailedLogin
                ? new Date(user?.lastFailedLogin).toLocaleString()
                : "N/A"}
            </Typography>
            <Typography variant="body1" sx={{ p: 1 }}>
              Failed Login Attempts: {user?.failedLoginAttempts}
            </Typography>
          </div>
          <div className="profile-edit-form-actions">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenEmailChangeDialog}
              style={{ marginRight: "10px" }}
            >
              Change Email
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenDialog}
            >
              Change Password
            </Button>
          </div>
          <PasswordChangeDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleChangePassword}
          />
          <EmailChangeDialog
            open={openEmailChangeDialog}
            onClose={handleCloseEmailChangeDialog}
            userId={user?._id}
            onCancel={handleEmailChangeCancel}
          />
        </>
      )}
      {!user && (
        <Typography color="error">There is user account details</Typography>
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default UserAccount;
