import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { AuthContext } from "./AuthContext";
import { useActiveChat } from "./ActiveChatContext";
import { useDispatch } from "react-redux";
import { notificationsApi } from "../services/notifications";
import { postApi } from "../services/post";
import { groupApi } from "../services/group";
import { userApi } from "../services/user";
import { memberApi } from "../services/member";
import { categoryApi } from "../services/category";
import { listingApi } from "../services/listings";
import {
  categoryChatApi,
  useAddNewCategoryChatMessageMutation,
} from "../services/category-chat";
import {
  messagesApi,
  useMarkAsReadMessagesMutation,
  useAddNewMessageMutation,
} from "../services/messages";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { userId, token } = useContext(AuthContext);
  const { activeChatId } = useActiveChat();
  const [addNewMessage] = useAddNewMessageMutation();
  const [addNewCategoryChatMessage] = useAddNewCategoryChatMessageMutation();
  const dispatch = useDispatch();
  const [markAsReadMessages] = useMarkAsReadMessagesMutation();
  const [isInactive, setIsInactive] = useState(false);
  const [basicNotifications, setBasicNotifications] = useState([]);

  useEffect(() => {
    if (token && userId) {
      const newSocket = io(process.env.REACT_APP_BACKEND_URL, {
        query: { userId },
        autoConnect: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 5000,
      });

      setSocket(newSocket);

      newSocket.on("connect", () => console.log("WebSocket Connected"));
      newSocket.on("disconnect", () => console.log("WebSocket Disconnected"));
      newSocket.on("reconnect_attempt", (attempt) =>
        console.log(`Reconnect attempt ${attempt}`)
      );
      newSocket.on("reconnect_failed", () =>
        console.log("Reconnection failed")
      ); // Optionally notify the user or take other actions

      newSocket.on("newNotification", (data) => {
        dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
      });
      newSocket.on("groupUpdated", (data) => {
        dispatch(groupApi.util.invalidateTags(["Group"]));
      });
      newSocket.on("newChat", (data) => {
        dispatch(messagesApi.util.invalidateTags(["Messages"]));
      });
      newSocket.on("newMessage", (data) => {
        if (activeChatId === data.chatId) {
          addNewMessage(data?.message);
        }
        dispatch(messagesApi.util.invalidateTags(["Messages"]));
      });
      newSocket.on("postDeleted", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("messageDeleted", (data) => {
        dispatch(messagesApi.util.invalidateTags(["Messages"]));
      });
      newSocket.on("postLiked", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("postOnListLiked", (data) => {
        dispatch(postApi.util.invalidateTags([{ type: "Post", id: "LIST" }]));
      });
      newSocket.on("postUnliked", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("postOnListUnliked", (data) => {
        dispatch(postApi.util.invalidateTags([{ type: "Post", id: "LIST" }]));
      });
      newSocket.on("newPostComment", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("newPostCommentOnPostList", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("commentLiked", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("commentUnliked", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("commentLikedOnPostList", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("commentUnlikedOnPostList", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("newReplyToPostCommentOnPostList", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("replyLikedOnPostList", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("replyUnlikedOnPostList", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("newActivityOnPost", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("userStatusIsOnline", (data) => {
        dispatch(userApi.util.invalidateTags(["User"]));
        dispatch(memberApi.util.invalidateTags(["Member"]));
      });
      newSocket.on("userStatusIsOffline", (data) => {
        if (data.userId === userId) {
          dispatch(userApi.util.invalidateTags(["User"]));
          dispatch(memberApi.util.invalidateTags(["Member"]));
        }
      });
      newSocket.on("memberOnlineStatusIsOnline", (data) => {
        if (data.userId === userId) {
          dispatch(memberApi.util.invalidateTags(["Member"]));
        }
      });
      newSocket.on("memberOnlineStatusIsOffline", (data) => {
        if (data.userId === userId) {
          dispatch(memberApi.util.invalidateTags(["Member"]));
        }
      });
      newSocket.on("userOnlineStatusIsOnline", (data) => {
        if (data && data.userId === userId) {
          dispatch(postApi.util.invalidateTags(["Post"]));
        }
      });
      newSocket.on("userOnlineStatusIsOffline", (data) => {
        if (data.userId === userId) {
          dispatch(postApi.util.invalidateTags(["Post"]));
        }
      });
      newSocket.on("newCategory", (data) => {
        dispatch(categoryApi.util.invalidateTags(["Category"]));
      });
      newSocket.on("categoriesDeleted", (data) => {
        dispatch(categoryApi.util.invalidateTags(["Category"]));
      });
      newSocket.on("attendEvent", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("unattendEvent", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("acknowledgedAnnouncement", (data) => {
        if (data.userId === userId) {
          dispatch(postApi.util.invalidateTags(["Post"]));
        }
      });
      newSocket.on("announcement", (announcementData) => {
        showAnnouncement(announcementData);
      });
      newSocket.on("newCategoryChatMessage", (data) => {
        if (data.userId === userId) {
          if (data.chatId) {
            addNewCategoryChatMessage(data?.message);
          }
          dispatch(categoryChatApi.util.invalidateTags(["CategoryChat"]));
        }
      });
      newSocket.on("updatePostCategoryBadge", (data) => {
        if (data.categoryId) {
          dispatch(postApi.util.invalidateTags(["PostBadge"]));
        }
      });
      newSocket.on("updatePostTierBadge", (data) => {
        if (data.tierId) {
          dispatch(postApi.util.invalidateTags(["PostTierBadge"]));
        }
      });
      newSocket.on("updateChatCategoryBadge", (data) => {
        if (data.categoryId) {
          dispatch(postApi.util.invalidateTags(["PostBadge"]));
        }
      });
      newSocket.on("postMigrated", (data) => {
        dispatch(postApi.util.invalidateTags(["Post"]));
      });
      newSocket.on("postDataUploaded", (data) => {
        if (data.forUserById === userId) {
          dispatch(postApi.util.invalidateTags([{ type: "Post", id: "LIST" }]));
        }
      });
      newSocket.on("listingDataUploaded", (data) => {
        if (data.forUserById === userId) {
          dispatch(
            listingApi.util.invalidateTags([{ type: "Listing", id: "LIST" }])
          );
        }
      });
      newSocket.on("basicNotification", (data) => {
        if (data.forUserById === userId && data.message) {
          setBasicNotifications((prevNotifications) => [
            ...prevNotifications,
            { id: Date.now(), message: data.message },
          ]);
        }
      });

      return () => newSocket.close();
    }
  }, [
    userId,
    token,
    dispatch,
    markAsReadMessages,
    activeChatId,
    addNewMessage,
    addNewCategoryChatMessage,
  ]);

  useEffect(() => {
    let time;

    const inactivityTime = () => {
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeypress = resetTimer;

      function logout() {
        if (!isInactive) {
          setIsInactive(true);
          if (socket) {
            socket.emit("userInactive");
          }
        }
      }

      function resetTimer() {
        clearTimeout(time);
        if (isInactive) {
          setIsInactive(false);
          if (socket) {
            socket.emit("userActive");
          }
        }
        time = setTimeout(logout, 600000);
      }
    };

    inactivityTime();

    return () => {
      clearTimeout(time);
    };
  }, [userId, socket, isInactive]);

  useEffect(() => {
    if (socket && isInactive) {
      socket.emit("userInactive", { userId });
    }
  }, [socket, isInactive, userId]);

  const [announcement, setAnnouncement] = useState(null);

  const showAnnouncement = (announcementData) => {
    setAnnouncement(announcementData);
  };

  const removeBasicNotification = (id) => {
    setBasicNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        announcement,
        showAnnouncement,
        basicNotifications,
        removeBasicNotification,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
