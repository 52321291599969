import Navbar from "./GenericNavbar";
import { handleCookieConsent } from "./helpers/CookieConsent";
import { Box, Typography, Button, Paper } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";

const IFrame = ({ category }) => {
  const [iframeError, setIframeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cookieConsent, setCookieConsent] = useState(
    () => Cookies.get("cookieConsent") === "true"
  );
  const iframeURL = category?.iFrameURL || localStorage.getItem("iframeURL");

  const handleCookieConsentClick = () => {
    handleCookieConsent(); // Use the imported function
    setCookieConsent(true);
  };

  const handleIframeLoad = () => {
    setIframeError(false);
    setErrorMessage("");
  };

  const handleIframeError = (errorEvent) => {
    console.error("Error loading iframe:", errorEvent);
    setIframeError(true);
    setErrorMessage("Failed to load iframe content");
  };

  useEffect(() => {
    const iframe = document.getElementById("reacirc-iframe");

    const handleLoadError = () => {
      if (iframe && iframe.contentWindow) {
        try {
          const doc = iframe.contentWindow.document;
          if (!doc) {
            throw new Error("Cross-origin restriction");
          }
        } catch (e) {
          handleIframeError(e);
        }
      }
    };

    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
      iframe.addEventListener("error", handleLoadError);
    }

    const handleMessage = (event) => {
      if (event.origin === window.location.origin) {
        const iframe = document.getElementById("reacirc-iframe");
        if (iframe && event.source === iframe.contentWindow) {
          iframe.style.height = event.data.height + "px";
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
        iframe.removeEventListener("error", handleLoadError);
      }
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeURL]);

  useEffect(() => {
    if (iframeURL) {
      localStorage.setItem("iframeURL", iframeURL);
    }
  }, [iframeURL]);

  return (
    <>
      <Navbar navBarName={category?.name} />
      <Box sx={{ width: "100%", height: "calc(100vh - 64px)" }}>
        {!cookieConsent ? (
          <Paper sx={{ padding: 2, textAlign: "center", marginTop: 4 }}>
            <Typography variant="h6">
              We use cookies to improve your experience. By using our site, you
              consent to cookies.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCookieConsentClick}
              sx={{ marginTop: 2 }}
            >
              Accept Cookies
            </Button>
          </Paper>
        ) : iframeError ? (
          <Typography
            variant="h6"
            sx={{ color: "red", textAlign: "center", marginTop: 4 }}
          >
            This site does not allow embedding in iframes. {errorMessage}
          </Typography>
        ) : (
          <iframe
            id="reacirc-iframe"
            src={iframeURL}
            width="100%"
            height="100%"
            allow="autoplay; encrypted-media"
            style={{ border: "none" }}
            title={category?._id || "Embedded Content"}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
          ></iframe>
        )}
      </Box>
    </>
  );
};

export default IFrame;
