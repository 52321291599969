import React, { useState, useContext, useEffect } from "react";
import { useInviteNewMemberMutation } from "../../../services/team";
import { useGetAllTeamRolesQuery } from "../../../services/team-role";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const InviteNewMemberToTeamDialog = ({
  open,
  onClose,
  teamId,
  tierId,
  teamName,
}) => {
  const { userId } = useContext(AuthContext);
  const [memberEmail, setMemberEmail] = useState("");
  const [teamRoleId, setTeamRoleId] = useState("");
  const [inviteNewMember] = useInviteNewMemberMutation();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const { data: teamRoles, isLoading: loadingTeamRoles } =
    useGetAllTeamRolesQuery(teamId, {
      skip: !teamId,
    });
  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await inviteNewMember({
      memberEmail,
      userId,
      teamRoles,
      teamId,
      tierId,
      teamRoleId,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error sending the member invitation link. ${result?.error?.data?.msg}`,
      });
    }
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(memberEmail));
  }, [memberEmail]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Invite New Member to the Team: {teamName}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                label="Member Email"
                name="Member Email"
                value={memberEmail}
                onChange={(e) => setMemberEmail(e.target.value.toLowerCase())}
                fullWidth
                required
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <InputLabel id="role-label">Team Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="roleId"
                  value={teamRoleId}
                  onChange={(e) => setTeamRoleId(e.target.value)}
                  fullWidth
                >
                  {loadingTeamRoles ? (
                    <MenuItem value="">
                      <div className="loading-spinner">
                        <CircularProgress size={24} />
                      </div>
                    </MenuItem>
                  ) : (
                    teamRoles?.map((role) => (
                      <MenuItem key={role?._id} value={role?._id}>
                        {role?.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!isEmailValid}
        >
          Invite Member
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default InviteNewMemberToTeamDialog;
