import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const landingPageApi = createApi({
  reducerPath: "landingPageApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/landingPage/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["landingPage", "components", "availableComponents"],
  endpoints: (builder) => ({
    // Get page by tier ID
    getPageByTierId: builder.query({
      query: (tierId) => ({
        url: `page/tier/${tierId}`,
        method: "GET",
      }),
      providesTags: (result, error, tierId) => [
        { type: "landingPage", id: tierId },
      ],
    }),

    // Create new page
    createPage: builder.mutation({
      query: (pageData) => ({
        url: "page",
        method: "POST",
        body: pageData,
      }),
      invalidatesTags: (result, error, { tierId }) => [
        { type: "landingPage", id: tierId },
      ],
    }),

    // Get components for a specific page
    getComponentsByPageId: builder.query({
      query: (pageId) => ({
        url: `components/${pageId}`,
        method: "GET",
      }),
      providesTags: (result, error, pageId) => [
        { type: "components", id: pageId },
        ...(result?.map((component) => ({
          type: "components",
          id: component.id,
        })) || []),
      ],
    }),

    // Update component
    updateComponent: builder.mutation({
      query: ({ pageId, componentId, componentData }) => ({
        url: `components/${componentId}`,
        method: "PUT",
        body: {
          ...componentData,
          pageId,
        },
      }),
      invalidatesTags: (result, error, { componentId, pageId }) => [
        { type: "components", id: componentId },
        { type: "components", id: pageId },
      ],
      transformResponse: (response) => response._id,
    }),

    // Get available component templates
    getAvailableComponents: builder.query({
      query: () => ({
        url: "available-components",
        method: "GET",
      }),
      providesTags: ["availableComponents"],
    }),

    // Delete component
    deleteComponent: builder.mutation({
      query: ({ pageId, componentId }) => {

        return {
          url: `components/${componentId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, { componentId, pageId }) => {

        return [
          { type: "components", id: componentId },
          { type: "components", id: pageId },
        ];
      },
    }),

    // Update page settings
    updatePage: builder.mutation({
      query: ({ pageId, pageData }) => ({
        url: `page/${pageId}`,
        method: "PUT",
        body: pageData,
      }),
      invalidatesTags: (result, error, { pageId }) => [
        { type: "landingPage", id: pageId },
      ],
    }),
  }),
});

export const {
  useGetPageByTierIdQuery,
  useCreatePageMutation,
  useGetComponentsByPageIdQuery,
  useUpdateComponentMutation,
  useGetAvailableComponentsQuery,
  useDeleteComponentMutation,
  useUpdatePageMutation,
} = landingPageApi;

export default landingPageApi;
