import {
  useGetMemberNotificationsQuery,
  useUpdateMemberNotificationsMutation,
} from "../../../services/member";
import {
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Button,
  Box,
  Paper,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const MemberNotificationsTab = ({ member }) => {
  const {
    data: preferences,
    isLoading,
    error,
  } = useGetMemberNotificationsQuery(member._id);
  const [updateMemberNotifications] = useUpdateMemberNotificationsMutation();
  const [localPreferences, setLocalPreferences] = useState({
    emailNotifications: false,
    smsNotifications: false,
    pushNotifications: false,
  });

  useEffect(() => {
    if (preferences) {
      setLocalPreferences(preferences);
    }
  }, [preferences]);

  const handleChange = async (event) => {
    const { name, checked } = event.target;
    const updatedPreferences = { ...localPreferences, [name]: checked };
    setLocalPreferences(updatedPreferences);

    try {
      await updateMemberNotifications({
        memberId: member._id,
        updateData: updatedPreferences,
      }).unwrap();
    } catch (error) {
      console.error("Failed to save preferences:", error);
    }
  };

  const allNotificationsDisabled =
    !preferences?.emailNotifications &&
    !preferences?.smsNotifications &&
    !preferences?.pushNotifications;

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading preferences</Typography>;

  return (
    <div
      style={{
        padding: "5px",
      }}
    >
      <Paper elevation={3} style={{ padding: "10px" }}>
        <Typography variant="h5">Notification Preferences</Typography>
        <Grid container spacing={2} width={"100%"}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={localPreferences.emailNotifications}
                  onChange={handleChange}
                  name="emailNotifications"
                  color="primary"
                />
              }
              label="Email Notifications"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={localPreferences.smsNotifications}
                  onChange={handleChange}
                  name="smsNotifications"
                  color="primary"
                />
              }
              label="SMS Notifications"
            />
          </Grid>
          <Grid item xs={12}>
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={localPreferences.pushNotifications}
                    onChange={handleChange}
                    name="pushNotifications"
                    color="primary"
                    disabled={true}
                  />
                }
                label="Push Notifications"
              />
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Note: Push feature will be available in the mobile app once
                  it's released.
                </Typography>
              </Box>
            </>
          </Grid>
          {allNotificationsDisabled && (
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography variant="body2" color="error">
                  Note: Messages from Management and any Direct messages will
                  still be sent out by email.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default MemberNotificationsTab;
