import { AuthContext } from "../../context/AuthContext";
import { useImportMemberMLSTransactionsMutation } from "../../services/member";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Papa from "papaparse";
import React, { useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";

const requiredFields = [
  { key: "mlsNumber", label: "MLS®" },
  { key: "status", label: "S" },
  { key: "propertySubType", label: "Property Sub Type" },
  { key: "propertyType", label: "Property Type" },
  { key: "address", label: "Address" },
  { key: "subArea", label: "Sub Area" },
  { key: "area", label: "Area" },
  { key: "listPrice", label: "Price Current" },
  { key: "buyerName", label: "Buyer" },
  { key: "coBuyerName", label: "Co Buyer" },
  { key: "coBuyer2Name", label: "Co Buyer 2" },
  { key: "coLicenseeName", label: "Co Licensee" },
  { key: "coLicensee2Name", label: "Co Licensee 2" },
  { key: "dateSold", label: "Date Sold" },
  { key: "licenseeName", label: "Licensee" },
  { key: "priceSold", label: "Price Sold" },
  { key: "dateListed", label: "Date Listed" },
  { key: "daysOnMarket", label: "Days On Market" },
];

const LoadTransactionsDialog = ({ open, onClose }) => {
  const [mlsContent, setMlsContent] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);
  const [fileName, setFileName] = useState("");
  const [importSummary, setImportSummary] = useState(null);
  const [missingColumns, setMissingColumns] = useState([]);

  const { userId, userName } = useContext(AuthContext);
  const [importMemberMLSTransactions] =
    useImportMemberMLSTransactionsMutation();

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      setMlsContent(text);
    };
    acceptedFiles.forEach((file) => {
      reader.readAsText(file);
      setFileName(file.name);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleAnalyze = () => {
    setMissingColumns([]);
    Papa.parse(mlsContent, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const cleanNumber = (value) => {
          return value ? parseFloat(value.replace(/[$,]/g, "")) : 0;
        };

        const cleanValue = (value) => {
          return value ? value.replace(/\*+$/, "").trim() : "";
        };
        // Check for mandatory fields and collect missing fields

        const headers = results.meta.fields;
        const missingColumns = requiredFields
          .filter((field) => !headers.includes(field.label))
          .map((field) => field.label);

        if (missingColumns.length > 0) {
          setMissingColumns(missingColumns);
          setTransactions([]);
          setValidCount(0);
          setInvalidCount(0);
          return;
        }

        const transactions = results.data.map((row) => {
          const transaction = {
            mlsNumber: cleanValue(row["MLS®"]),
            status: cleanValue(row["S"]),
            propertySubType: cleanValue(row["Property Sub Type"]),
            propertyType: cleanValue(row["Property Type"]),
            address: cleanValue(row["Address"]),
            subArea: cleanValue(row["Sub Area"]),
            area: cleanValue(row["Area"]),
            listPrice: cleanNumber(cleanValue(row["Price Current"])),
            beds: parseInt(cleanValue(row["Beds"])) || 0,
            buyerName: cleanValue(row["Buyer"]),
            coBuyerName: cleanValue(row["Co Buyer"]),
            coBuyer2Name: cleanValue(row["Co Buyer 2"]),
            coLicenseeName: cleanValue(row["Co Licensee"]),
            coLicensee2Name: cleanValue(row["Co Licensee 2"]),
            dateSold: cleanValue(row["Date Sold"]),
            licenseeName: cleanValue(row["Licensee"]),
            priceSold: cleanNumber(cleanValue(row["Price Sold"])),
            baths: parseInt(cleanValue(row["Baths"])) || 0,
            dateListed: cleanValue(row["Date Listed"]),
            daysOnMarket: parseInt(cleanValue(row["Days On Market"])) || 0,
            flagged: false,
          };

          if (
            cleanValue(transaction.buyerName).toLowerCase() !==
              userName.toLowerCase() &&
            cleanValue(transaction.coBuyerName).toLowerCase() !==
              userName.toLowerCase() &&
            cleanValue(transaction.coBuyer2Name).toLowerCase() !==
              userName.toLowerCase() &&
            cleanValue(transaction.coLicenseeName).toLowerCase() !==
              userName.toLowerCase() &&
            cleanValue(transaction.coLicensee2Name).toLowerCase() !==
              userName.toLowerCase() &&
            cleanValue(transaction.licenseeName).toLowerCase() !==
              userName.toLowerCase()
          ) {
            transaction.flagged = true;
          }

          return transaction;
        });

        const validTransactions = transactions.filter(
          (transaction) => !transaction.flagged
        );

        const invalidTransactions = transactions.filter(
          (transaction) => transaction.flagged
        );

        setValidCount(validTransactions.length);
        setInvalidCount(transactions.length - validTransactions.length);
        setTransactions(transactions);
      },
    });
  };

  const handleImport = async () => {
    try {
      const response = await importMemberMLSTransactions({
        userId,
        transactions,
      }).unwrap();
      setImportSummary(response);
    } catch (error) {
      console.error("Failed to import transactions:", error);
    }
  };

  const handleClose = () => {
    setMlsContent("");
    setTransactions([]);
    setValidCount(0);
    setInvalidCount(0);
    setFileName("");
    setImportSummary(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Load Transactions</DialogTitle>
      <DialogContent>
        <Box
          {...getRootProps()}
          p={2}
          border="1px dashed grey"
          textAlign="center"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the files here ...</Typography>
          ) : (
            <Typography>
              Drag 'n' drop some files here, or click to select files
            </Typography>
          )}
        </Box>
        {fileName && (
          <Typography variant="subtitle1" mt={2}>
            Loaded File: {fileName}
          </Typography>
        )}
        <TextField
          label="MLS Content"
          multiline
          rows={10}
          value={mlsContent}
          onChange={(e) => setMlsContent(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ display: "none" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAnalyze}
          disabled={!fileName}
        >
          Analyze
        </Button>

        {missingColumns.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1" color="error">
              Missing Columns: {missingColumns.join(", ")}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
              <strong>Required Fields:</strong>{" "}
              {requiredFields.map((field) => field.key).join(", ")}
            </Typography>
          </Box>
        )}

        {transactions.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6">Transactions Summary</Typography>
            <Typography variant="subtitle1">
              Valid Transactions: {validCount}
            </Typography>
            <Typography variant="subtitle1">
              Invalid Transactions: {invalidCount}
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleImport}
            >
              Import Transactions
            </Button>
            {importSummary && (
              <Box mt={4} p={2} border="2px solid blue" borderRadius="8px">
                <Typography variant="h5">{importSummary.msg}</Typography>
                <Typography variant="subtitle1">
                  Updated or Loaded Count: {importSummary.updatedOrLoadedCount}
                </Typography>
                <Typography variant="subtitle1">
                  Rejected Count: {importSummary.rejectedCount}
                </Typography>
                <Button onClick={onClose} color="primary">
                  Close
                </Button>
              </Box>
            )}
            <TableContainer component={Paper} mt={2}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>MLS #</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Property Type</TableCell>
                    <TableCell>Property Sub Type</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Area</TableCell>
                    <TableCell>Sub Area</TableCell>
                    <TableCell>List Price</TableCell>
                    <TableCell>Beds</TableCell>
                    <TableCell>Buyer</TableCell>
                    <TableCell>Co Buyer</TableCell>
                    <TableCell>Price Sold</TableCell>
                    <TableCell>Baths</TableCell>
                    <TableCell>Date Listed</TableCell>
                    <TableCell>Days On Market</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: transaction.flagged
                          ? "red"
                          : "lightgreen",
                      }}
                    >
                      <TableCell>{transaction.mlsNumber}</TableCell>
                      <TableCell>{transaction.status}</TableCell>
                      <TableCell>{transaction.propertyType}</TableCell>
                      <TableCell>{transaction.propertySubType}</TableCell>
                      <TableCell>{transaction.address}</TableCell>
                      <TableCell>{transaction.area}</TableCell>
                      <TableCell>{transaction.subArea}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(transaction.listPrice)}
                      </TableCell>
                      <TableCell>{transaction.beds}</TableCell>
                      <TableCell>{transaction.buyerName}</TableCell>
                      <TableCell>{transaction.coBuyerName}</TableCell>
                      <TableCell>{transaction.coBuyer2Name}</TableCell>
                      <TableCell>{transaction.coLicenseeName}</TableCell>
                      <TableCell>{transaction.coLicensee2Name}</TableCell>
                      <TableCell>{transaction.dateSold}</TableCell>
                      <TableCell>{transaction.licenseeName}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(transaction.priceSold)}
                      </TableCell>
                      <TableCell>{transaction.baths}</TableCell>
                      <TableCell>{transaction.dateListed}</TableCell>
                      <TableCell>{transaction.daysOnMarket}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadTransactionsDialog;
